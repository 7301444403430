<template >
    <form >
        <div class="text-gray-900 flex mb-5" >
            <div class="text-lg font-medium " >
                Auftrag anlegen
            </div >
        </div >

        <div class="mt-8" >
            <steps :steps="4" :step="step" ></steps >
        </div >

        <hr class="mt-8 mb-5" >

        <div class="flex flex-col justify-between h-full" >
            <div v-if="step == 1" class="flex-1" >
                <div class="flex text-gray-600 space-x-10" >
                    <div class="font-medium" >
                        Adresse eingeben
                    </div >
                </div >
                <ul v-if="validationErrors.hints.length"
                    class="list-disc list-inside grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-x-4 text-primary-dark" >
                    <li v-for="hint in validationErrors.hints" >{{ hint }}</li >
                </ul >
                <hr class="my-5" >

                <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4" >

                    <div >
                        <label for="first_name" class="block text-sm font-medium text-gray-700" >Vorname</label >
                        <div class="mt-1" >
                            <input v-model="order.customer.first_name" type="text" id="first_name" name="first_name"
                                   autocomplete="given-name"
                                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-indigo-500 sm:text-sm"
                                   :class="validationErrors.fields.first_name ? ['!border-primary'] : []"
                                   @change="validateAgain" />
                        </div >
                    </div >
                    <div >
                        <label for="last_name" class="block text-sm font-medium text-gray-700" >Nachname</label >
                        <div class="mt-1" >
                            <input v-model="order.customer.last_name" type="text" id="last_name" name="last_name"
                                   autocomplete="family-name"
                                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                   :class="validationErrors.fields.last_name ? ['!border-primary'] : []"
                                   @change="validateAgain" />
                        </div >
                    </div >
                    <div >
                        <label for="company" class="block text-sm font-medium text-gray-700" >Firma</label >
                        <div class="mt-1" >
                            <input v-model="order.customer.company" type="text" id="company" name="company"
                                   autocomplete="organization"
                                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                   :class="validationErrors.fields.company ? ['!border-primary'] : []"
                                   @change="validateAgain" />
                        </div >
                    </div >
                    <div >
                        <label for="company_suffix"
                               class="block text-sm font-medium text-gray-700" >Firmenzusatz</label >
                        <div class="mt-1" >
                            <input v-model="order.customer.company_suffix" type="text" id="company_suffix"
                                   name="company_suffix"
                                   autocomplete="on"
                                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        </div >
                    </div >
                    <div class="col-span-full" >
                        <label for="addressSupplement"
                               class="block text-sm font-medium text-gray-700" >Adresszusatz</label >
                        <div class="mt-1" >
                            <input v-model="order.customer.address_supplement" type="text" id="addressSupplement"
                                   name="addressSupplement"
                                   autocomplete="address-line-2"
                                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        </div >
                    </div >
                </div >
                <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4" >
                    <div class="col-span-full" >
                        <label for="country" class="block text-sm font-medium text-gray-700" >Land</label >
                        <div class="mt-1" >
                            <select v-model="order.customer.country" id="country"
                                    name="country"
                                    class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" >
                                <option v-for="country in countries" >{{ country.name }}</option >
                            </select >
                        </div >
                    </div >
                    <div class="col-span-full" >
                        <label for="address" class="block text-sm font-medium text-gray-700" >Straße +
                            Hausnummer</label >
                        <div class="mt-1" >
                            <input v-model="order.customer.address" type="text" id="address" name="address"
                                   autocomplete="address-line-1"
                                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                   :class="validationErrors.fields.address ? ['!border-primary'] : []"
                                   @change="validateAgain" />
                        </div >
                    </div >
                    <div >
                        <label for="city" class="block text-sm font-medium text-gray-700" >Stadt</label >
                        <div class="mt-1" >
                            <input v-model="order.customer.city" type="text" id="city" name="city"
                                   autocomplete="address-level-2"
                                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                   :class="validationErrors.fields.city ? ['!border-primary'] : []"
                                   @change="validateAgain" />
                        </div >
                    </div >
                    <div >
                        <label for="state" class="block text-sm font-medium text-gray-700" >Bundesland
                            (optional)</label >
                        <div class="mt-1" >
                            <input v-model="order.customer.state" type="text" id="state" name="state"
                                   autocomplete="address-level-1"
                                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        </div >
                    </div >
                    <div >
                        <label for="postal_code" class="block text-sm font-medium text-gray-700" >PLZ</label >
                        <div class="mt-1" >
                            <input v-model="order.customer.postal_code" type="text" id="postal_code" name="postal_code"
                                   autocomplete="postal-code"
                                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                   :class="validationErrors.fields.postal_code ? ['!border-primary'] : []"
                                   @change="validateAgain" />
                        </div >
                    </div >
                </div >
            </div >

            <div v-if="step == 2" class="flex-1" >
                <div class="flex text-gray-600 space-x-10" >
                    <div class="font-medium" >
                        Auftragsdetails anlegen
                    </div >
                </div >
                <ul v-if="validationErrors.hints.length"
                    class="list-disc list-inside grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-x-4 text-primary-dark" >
                    <li v-for="hint in validationErrors.hints" >{{ hint }}</li >
                </ul >
                <hr class="my-5" >
                <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4" >
                    <div >
                        <label for="customer_id" class="block text-sm font-medium text-gray-700" >Kundennummer (für B2B
                            Orders)</label >
                        <div class="mt-1" >
                            <input v-model="order.customer_id" type="text" id="customer_id" name="customer_id"
                                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        </div >
                    </div >
                    <div >
                        <label for="shipping_method"
                               class="block text-sm font-medium text-gray-700" >Versandart</label >
                        <div class="mt-1" >
                            <select v-model="order.shipping_method" id="shipping_method" name="shipping_method"
                                    class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" >
                                <option v-for="shippingMethod in shippingMethods" :value="shippingMethod.id" >
                                    {{ shippingMethod.name }}
                                </option >
                            </select >
                        </div >
                    </div >
                    <div >
                        <label for="external_order_id" class="block text-sm font-medium text-gray-700" >Externe
                            Auftragsnummer</label >
                        <div class="mt-1" >
                            <input v-model="order.external_order_id" type="text" id="external_order_id"
                                   name="external_order_id"
                                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        </div >
                    </div >
                    <div class="col-span-full" >
                        <label for="customer_id" class="block text-sm font-medium text-gray-700" >Kommentar
                            (optional)</label >
                        <div class="mt-1" >
                            <input v-model="order.comment" type="text" id="comment" name="comment"
                                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        </div >
                    </div >
                </div >
                <div class="mt-6 font-medium" >
                    Positionen
                </div >
                <div class="mt-4" >

                    <div v-for="(position, index) in order.positions" :key="index" class="flex mt-4 gap-4" >
                        <div class="" >
                            <button type="button"
                                    @click="() => {addPosition(index)}"
                                    class="h-full rounded-md border border-transparent bg-secondary py-2 px-4 text-sm text-white shadow-sm hover:bg-secondary-dark focus:outline-none focus:ring-2 focus:bg-secondary-dark focus:ring-offset-2 focus:ring-offset-gray-50" >
                                <PlusIcon class="h-4 w-4" aria-hidden="true" />
                            </button >
                        </div >
                        <div class="whitespace-nowrap py-2 px-2 text-sm text-gray-500" >
                            Pos. {{ index + 1 }}
                        </div >
                        <div class="w-full" >
                            <Multiselect v-model="position.product"
                                         mode="single"
                                         searchable="true"
                                         :options="searchProducts"
                                         :showNoResults="true"
                            >
                                <template v-slot:noResult >
                                    Keine Einträge gefunden
                                </template >
                            </Multiselect >
                        </div >
                        <div class="w-36" >
                            <input v-model="position.quantity" type="number" :min="1" :max="position.available"
                                   :id="'quantity-' + index" :name="'quantity-' + index"
                                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                   :class="validationErrors.fields.positions[index] ? ['!border-primary'] : []" />
                        </div >
                        <div >
                            <button type="button"
                                    @click="() => {removePosition(index)}"
                                    class="h-full rounded-md border border-transparent bg-primary py-2 px-4 text-sm text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:bg-primary-dark focus:ring-offset-2 focus:ring-offset-gray-50" >
                                <TrashIcon class="h-4 w-4" aria-hidden="true" />
                            </button >
                        </div >
                    </div >
                    <div class="flex mt-4" >
                        <button type="button"
                                @click="() => addPosition()"
                                class="h-[42px] rounded-md border border-transparent bg-secondary py-2 px-4 text-sm text-white shadow-sm hover:bg-secondary-dark focus:outline-none focus:ring-2 focus:bg-secondary-dark focus:ring-offset-2 focus:ring-offset-gray-50" >
                            <PlusIcon class="h-4 w-4" aria-hidden="true" />
                        </button >
                    </div >
                </div >
            </div >

            <div v-if="step == 3" class="flex-1" >
                <div class="flex text-gray-600 space-x-10" >
                    <div class="font-medium" >
                        Auftrag übermitteln
                    </div >
                </div >
                <hr class="my-5" >
                <div class="mt-4" >
                    <span class="inline-flex items-center rounded-full bg-yellow-300 px-3 py-0.5 text-sm text-black" >
                        <ExclamationTriangleIcon class="h-5 mr-2" /> Bitte beachte, dass es bis zu einer Stunde dauern kann, ehe der Aufrtag im Dashboard sichtbar ist.
                    </span >
                </div >
                <div class="mt-4 font-medium" >
                    Adresse
                </div >
                <div class="mt-4" >
                    <div >{{ order.customer.first_name }} {{ order.customer.last_name }}</div >
                    <div >{{ order.customer.company }} {{ order.customer.company_suffix }}</div >
                    <div >{{ order.customer.address_supplement }}</div >
                    <div >{{ order.customer.country }}</div >
                    <div >{{ order.customer.address }}</div >
                    <div >{{ order.customer.postal_code }} {{ order.customer.city }}
                        <template v-if="order.customer.state" >, {{ order.customer.state }}</template >
                    </div >
                </div >
                <div class="mt-4 font-medium" >
                    Auftragsdetails
                </div >
                <div class="mt-4" >
                    <div >Kundennummer: {{ order.customer_id }}</div >
                    <div >Versandart: {{ order.shipping_method }}</div >
                    <div >{{ order.comment }}</div >
                </div >
                <div class="mt-4 font-medium" >
                    Positionen
                </div >
                <div v-for="(position, index) in order.positions" class="mt-2 flex gap-2" >
                    <div class="whitespace-nowrap" >Pos. {{ index + 1 }}</div >
                    <div v-for="product in [products.find(e => e.article == position.product)]" >
                        {{ position.quantity }}x {{ product.name }} - GTIN {{ product.gtin }} - {{ product.id }}
                    </div >
                </div >
            </div >

            <div v-if="step == 4" class="flex-1" >
                <div v-if="submitSuccess === true" class="text-center" >
                    <div
                        class="mx-auto flex mt-16 mb-16 h-20 w-20 items-center justify-center rounded-full bg-green-100" >
                        <CheckIcon class="h-16 w-16 text-green-600" aria-hidden="true" />
                    </div >
                    <div class="mt-4 text-3xl" >Übermittlung erfolgreich</div >
                    <div class="mt-4" >Dein manueller Auftrag wurde übergeben und ist bald sichtbar.</div >
                </div >
                <div v-else-if="submitSuccess === false" class="text-center" >
                    <div
                        class="mx-auto flex mt-16 mb-16 h-20 w-20 items-center justify-center rounded-full bg-red-100" >
                        <ExclamationTriangleIcon class="h-16 w-16 text-red-600" aria-hidden="true" />
                    </div >
                    <div class="mt-4 text-3xl" >Übermittlung fehlgeschlagen</div >
                    <div class="mt-4" >Der Auftrag konnte nicht übermittelt werden.</div >
                    <div class="mt-4" >Fehlermeldung:</div >
                    <div class="mt-4" >{{ submitError.message }}</div >
                </div >
                <div v-else class="text-center" >
                    <div
                        class="mx-auto flex mt-16 mb-16 h-20 w-20 items-center justify-center rounded-full bg-gray-100" >
                        <InformationCircleIcon class="h-16 w-16 text-gray-600" aria-hidden="true" />
                    </div >
                    <div class="mt-4 text-3xl" >Auftrag wird übermittelt</div >
                </div >
            </div >

            <div class="mt-8 flex"
                 :class="step === 1 || (step == 4 && submitSuccess === true) ? 'justify-end' : 'justify-between'" >
                <button type="button"
                        v-if="step > 1 && submitSuccess !== true"
                        @click="previousStep"
                        class="rounded-md border border-transparent bg-secondary py-2 px-4 text-sm text-white shadow-sm hover:bg-secondary-dark focus:outline-none focus:ring-2 focus:bg-secondary-dark focus:ring-offset-2 focus:ring-offset-gray-50" >
                    Zurück
                </button >
                <button
                    v-if="step < 3"
                    type="button"
                    @click="nextStep"
                    class="rounded-md border border-transparent bg-secondary py-2 px-4 text-sm text-white shadow-sm hover:bg-secondary-dark focus:outline-none focus:ring-2 focus:bg-secondary-dark focus:ring-offset-2 focus:ring-offset-gray-50" >
                    Weiter
                </button >
                <button v-if="step === 4 && submitSuccess === false"
                        type="button"
                        @click="$emit('close-me')"
                        class="rounded-md border border-transparent bg-primary py-2 px-4 text-sm text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:bg-primary-dark focus:ring-offset-2 focus:ring-offset-gray-50" >
                    Fenster schließen
                </button >
                <button v-if="step === 3"
                        type="button"
                        @click="submitOrder"
                        class="rounded-md border border-transparent bg-primary py-2 px-4 text-sm text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:bg-primary-dark focus:ring-offset-2 focus:ring-offset-gray-50" >
                    Senden
                </button >
                <button v-if="step == 4 && submitSuccess === true"
                        type="button"
                        @click="$emit('close-me')"
                        class="rounded-md border border-transparent bg-primary py-2 px-4 text-sm text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:bg-primary-dark focus:ring-offset-2 focus:ring-offset-gray-50" >
                    Schließen
                </button >

            </div >
        </div >
    </form >
</template >

<script >
import {CheckIcon, ExclamationTriangleIcon, InformationCircleIcon, PlusIcon, TrashIcon} from '@heroicons/vue/24/outline'
import Steps from './Steps.vue'
import Multiselect from '@vueform/multiselect'


export default {
    components: {
        PlusIcon,
        TrashIcon,
        CheckIcon,
        ExclamationTriangleIcon,
        InformationCircleIcon,
        Steps,
        Multiselect,
    },

    props: [
        "orderId",
    ],

    data() {
        return {
            order: {
                "customer": {
                    "country": "",
                    "first_name": "",
                    "last_name": "",
                    "company": "",
                    "company_suffix": "",
                    "address_supplement": "",
                    "address": "",
                    "city": "",
                    "state": "",
                    "postal_code": ""
                },
                "positions": [],
                "customer_id": "",
                "shipping_method": null,
                "comment": "",
                "external_order_id": "",
            },
            step: 1,
            products: [],
            shippingMethods: [],
            searchProducts: [],
            countries: [{"id": 276, "alpha2": "de", "alpha3": "deu", "name": "Deutschland"},
                {"id": 4, "alpha2": "af", "alpha3": "afg", "name": "Afghanistan"},
                {"id": 818, "alpha2": "eg", "alpha3": "egy", "name": "Ägypten"},
                {"id": 248, "alpha2": "ax", "alpha3": "ala", "name": "Åland"},
                {"id": 8, "alpha2": "al", "alpha3": "alb", "name": "Albanien"},
                {"id": 12, "alpha2": "dz", "alpha3": "dza", "name": "Algerien"},
                {"id": 16, "alpha2": "as", "alpha3": "asm", "name": "Amerikanisch-Samoa"},
                {"id": 850, "alpha2": "vi", "alpha3": "vir", "name": "Amerikanische Jungferninseln"},
                {"id": 20, "alpha2": "ad", "alpha3": "and", "name": "Andorra"},
                {"id": 24, "alpha2": "ao", "alpha3": "ago", "name": "Angola"},
                {"id": 660, "alpha2": "ai", "alpha3": "aia", "name": "Anguilla"},
                {"id": 10, "alpha2": "aq", "alpha3": "ata", "name": "Antarktis (Sonderstatus durch Antarktisvertrag)"},
                {"id": 28, "alpha2": "ag", "alpha3": "atg", "name": "Antigua und Barbuda"},
                {"id": 226, "alpha2": "gq", "alpha3": "gnq", "name": "Äquatorialguinea"},
                {"id": 32, "alpha2": "ar", "alpha3": "arg", "name": "Argentinien"},
                {"id": 51, "alpha2": "am", "alpha3": "arm", "name": "Armenien"},
                {"id": 533, "alpha2": "aw", "alpha3": "abw", "name": "Aruba"},
                {"id": 31, "alpha2": "az", "alpha3": "aze", "name": "Aserbaidschan"},
                {"id": 231, "alpha2": "et", "alpha3": "eth", "name": "Äthiopien"},
                {"id": 36, "alpha2": "au", "alpha3": "aus", "name": "Australien"},
                {"id": 44, "alpha2": "bs", "alpha3": "bhs", "name": "Bahamas"},
                {"id": 48, "alpha2": "bh", "alpha3": "bhr", "name": "Bahrain"},
                {"id": 50, "alpha2": "bd", "alpha3": "bgd", "name": "Bangladesch"},
                {"id": 52, "alpha2": "bb", "alpha3": "brb", "name": "Barbados"},
                {"id": 112, "alpha2": "by", "alpha3": "blr", "name": "Belarus"},
                {"id": 56, "alpha2": "be", "alpha3": "bel", "name": "Belgien"},
                {"id": 84, "alpha2": "bz", "alpha3": "blz", "name": "Belize"},
                {"id": 204, "alpha2": "bj", "alpha3": "ben", "name": "Benin"},
                {"id": 60, "alpha2": "bm", "alpha3": "bmu", "name": "Bermuda"},
                {"id": 64, "alpha2": "bt", "alpha3": "btn", "name": "Bhutan"},
                {"id": 68, "alpha2": "bo", "alpha3": "bol", "name": "Bolivien"},
                {"id": 535, "alpha2": "bq", "alpha3": "bes", "name": "Bonaire, Saba, Sint Eustatius"},
                {"id": 70, "alpha2": "ba", "alpha3": "bih", "name": "Bosnien und Herzegowina"},
                {"id": 72, "alpha2": "bw", "alpha3": "bwa", "name": "Botswana"},
                {"id": 74, "alpha2": "bv", "alpha3": "bvt", "name": "Bouvetinsel"},
                {"id": 76, "alpha2": "br", "alpha3": "bra", "name": "Brasilien"},
                {"id": 92, "alpha2": "vg", "alpha3": "vgb", "name": "Britische Jungferninseln"},
                {"id": 86, "alpha2": "io", "alpha3": "iot", "name": "Britisches Territorium im Indischen Ozean"},
                {"id": 96, "alpha2": "bn", "alpha3": "brn", "name": "Brunei"},
                {"id": 100, "alpha2": "bg", "alpha3": "bgr", "name": "Bulgarien"},
                {"id": 854, "alpha2": "bf", "alpha3": "bfa", "name": "Burkina Faso"},
                {"id": 108, "alpha2": "bi", "alpha3": "bdi", "name": "Burundi"},
                {"id": 152, "alpha2": "cl", "alpha3": "chl", "name": "Chile"},
                {"id": 156, "alpha2": "cn", "alpha3": "chn", "name": "Volksrepublik China"},
                {"id": 184, "alpha2": "ck", "alpha3": "cok", "name": "Cookinseln"},
                {"id": 188, "alpha2": "cr", "alpha3": "cri", "name": "Costa Rica"},
                {"id": 531, "alpha2": "cw", "alpha3": "cuw", "name": "Curaçao"},
                {"id": 208, "alpha2": "dk", "alpha3": "dnk", "name": "Dänemark"},
                {"id": 212, "alpha2": "dm", "alpha3": "dma", "name": "Dominica"},
                {"id": 214, "alpha2": "do", "alpha3": "dom", "name": "Dominikanische Republik"},
                {"id": 262, "alpha2": "dj", "alpha3": "dji", "name": "Dschibuti"},
                {"id": 218, "alpha2": "ec", "alpha3": "ecu", "name": "Ecuador"},
                {"id": 384, "alpha2": "ci", "alpha3": "civ", "name": "Elfenbeinküste"},
                {"id": 222, "alpha2": "sv", "alpha3": "slv", "name": "El Salvador"},
                {"id": 232, "alpha2": "er", "alpha3": "eri", "name": "Eritrea"},
                {"id": 233, "alpha2": "ee", "alpha3": "est", "name": "Estland"},
                {"id": 748, "alpha2": "sz", "alpha3": "swz", "name": "Eswatini"},
                {"id": 238, "alpha2": "fk", "alpha3": "flk", "name": "Falklandinseln"},
                {"id": 234, "alpha2": "fo", "alpha3": "fro", "name": "Färöer"},
                {"id": 242, "alpha2": "fj", "alpha3": "fji", "name": "Fidschi"},
                {"id": 246, "alpha2": "fi", "alpha3": "fin", "name": "Finnland"},
                {"id": 250, "alpha2": "fr", "alpha3": "fra", "name": "Frankreich"},
                {"id": 254, "alpha2": "gf", "alpha3": "guf", "name": "Französisch-Guayana"},
                {"id": 258, "alpha2": "pf", "alpha3": "pyf", "name": "Französisch-Polynesien"},
                {"id": 260, "alpha2": "tf", "alpha3": "atf", "name": "Französische Süd- und Antarktisgebiete"},
                {"id": 266, "alpha2": "ga", "alpha3": "gab", "name": "Gabun"},
                {"id": 270, "alpha2": "gm", "alpha3": "gmb", "name": "Gambia"},
                {"id": 268, "alpha2": "ge", "alpha3": "geo", "name": "Georgien"},
                {"id": 288, "alpha2": "gh", "alpha3": "gha", "name": "Ghana"},
                {"id": 292, "alpha2": "gi", "alpha3": "gib", "name": "Gibraltar"},
                {"id": 308, "alpha2": "gd", "alpha3": "grd", "name": "Grenada"},
                {"id": 300, "alpha2": "gr", "alpha3": "grc", "name": "Griechenland"},
                {"id": 304, "alpha2": "gl", "alpha3": "grl", "name": "Grönland"},
                {"id": 312, "alpha2": "gp", "alpha3": "glp", "name": "Guadeloupe"},
                {"id": 316, "alpha2": "gu", "alpha3": "gum", "name": "Guam"},
                {"id": 320, "alpha2": "gt", "alpha3": "gtm", "name": "Guatemala"},
                {"id": 831, "alpha2": "gg", "alpha3": "ggy", "name": "Guernsey (Kanalinsel)"},
                {"id": 324, "alpha2": "gn", "alpha3": "gin", "name": "Guinea"},
                {"id": 624, "alpha2": "gw", "alpha3": "gnb", "name": "Guinea-Bissau"},
                {"id": 328, "alpha2": "gy", "alpha3": "guy", "name": "Guyana"},
                {"id": 332, "alpha2": "ht", "alpha3": "hti", "name": "Haiti"},
                {"id": 334, "alpha2": "hm", "alpha3": "hmd", "name": "Heard und McDonaldinseln"},
                {"id": 340, "alpha2": "hn", "alpha3": "hnd", "name": "Honduras"},
                {"id": 344, "alpha2": "hk", "alpha3": "hkg", "name": "Hongkong"},
                {"id": 356, "alpha2": "in", "alpha3": "ind", "name": "Indien"},
                {"id": 360, "alpha2": "id", "alpha3": "idn", "name": "Indonesien"},
                {"id": 833, "alpha2": "im", "alpha3": "imn", "name": "Insel Man"},
                {"id": 368, "alpha2": "iq", "alpha3": "irq", "name": "Irak"},
                {"id": 364, "alpha2": "ir", "alpha3": "irn", "name": "Iran"},
                {"id": 372, "alpha2": "ie", "alpha3": "irl", "name": "Irland"},
                {"id": 352, "alpha2": "is", "alpha3": "isl", "name": "Island"},
                {"id": 376, "alpha2": "il", "alpha3": "isr", "name": "Israel"},
                {"id": 380, "alpha2": "it", "alpha3": "ita", "name": "Italien"},
                {"id": 388, "alpha2": "jm", "alpha3": "jam", "name": "Jamaika"},
                {"id": 392, "alpha2": "jp", "alpha3": "jpn", "name": "Japan"},
                {"id": 887, "alpha2": "ye", "alpha3": "yem", "name": "Jemen"},
                {"id": 832, "alpha2": "je", "alpha3": "jey", "name": "Jersey (Kanalinsel)"},
                {"id": 400, "alpha2": "jo", "alpha3": "jor", "name": "Jordanien"},
                {"id": 136, "alpha2": "ky", "alpha3": "cym", "name": "Kaimaninseln"},
                {"id": 116, "alpha2": "kh", "alpha3": "khm", "name": "Kambodscha"},
                {"id": 120, "alpha2": "cm", "alpha3": "cmr", "name": "Kamerun"},
                {"id": 124, "alpha2": "ca", "alpha3": "can", "name": "Kanada"},
                {"id": 132, "alpha2": "cv", "alpha3": "cpv", "name": "Kap Verde"},
                {"id": 398, "alpha2": "kz", "alpha3": "kaz", "name": "Kasachstan"},
                {"id": 634, "alpha2": "qa", "alpha3": "qat", "name": "Katar"},
                {"id": 404, "alpha2": "ke", "alpha3": "ken", "name": "Kenia"},
                {"id": 417, "alpha2": "kg", "alpha3": "kgz", "name": "Kirgisistan"},
                {"id": 296, "alpha2": "ki", "alpha3": "kir", "name": "Kiribati"},
                {"id": 166, "alpha2": "cc", "alpha3": "cck", "name": "Kokosinseln"},
                {"id": 170, "alpha2": "co", "alpha3": "col", "name": "Kolumbien"},
                {"id": 174, "alpha2": "km", "alpha3": "com", "name": "Komoren"},
                {"id": 180, "alpha2": "cd", "alpha3": "cod", "name": "Kongo, Demokratische Republik"},
                {"id": 178, "alpha2": "cg", "alpha3": "cog", "name": "Kongo, Republik"},
                {"id": 408, "alpha2": "kp", "alpha3": "prk", "name": "Korea, Nord (Nordkorea)"},
                {"id": 410, "alpha2": "kr", "alpha3": "kor", "name": "Korea, Süd (Südkorea)"},
                {"id": 191, "alpha2": "hr", "alpha3": "hrv", "name": "Kroatien"},
                {"id": 192, "alpha2": "cu", "alpha3": "cub", "name": "Kuba"},
                {"id": 414, "alpha2": "kw", "alpha3": "kwt", "name": "Kuwait"},
                {"id": 418, "alpha2": "la", "alpha3": "lao", "name": "Laos"},
                {"id": 426, "alpha2": "ls", "alpha3": "lso", "name": "Lesotho"},
                {"id": 428, "alpha2": "lv", "alpha3": "lva", "name": "Lettland"},
                {"id": 422, "alpha2": "lb", "alpha3": "lbn", "name": "Libanon"},
                {"id": 430, "alpha2": "lr", "alpha3": "lbr", "name": "Liberia"},
                {"id": 434, "alpha2": "ly", "alpha3": "lby", "name": "Libyen"},
                {"id": 438, "alpha2": "li", "alpha3": "lie", "name": "Liechtenstein"},
                {"id": 440, "alpha2": "lt", "alpha3": "ltu", "name": "Litauen"},
                {"id": 442, "alpha2": "lu", "alpha3": "lux", "name": "Luxemburg"},
                {"id": 446, "alpha2": "mo", "alpha3": "mac", "name": "Macau"},
                {"id": 450, "alpha2": "mg", "alpha3": "mdg", "name": "Madagaskar"},
                {"id": 454, "alpha2": "mw", "alpha3": "mwi", "name": "Malawi"},
                {"id": 458, "alpha2": "my", "alpha3": "mys", "name": "Malaysia"},
                {"id": 462, "alpha2": "mv", "alpha3": "mdv", "name": "Malediven"},
                {"id": 466, "alpha2": "ml", "alpha3": "mli", "name": "Mali"},
                {"id": 470, "alpha2": "mt", "alpha3": "mlt", "name": "Malta"},
                {"id": 504, "alpha2": "ma", "alpha3": "mar", "name": "Marokko"},
                {"id": 584, "alpha2": "mh", "alpha3": "mhl", "name": "Marshallinseln"},
                {"id": 474, "alpha2": "mq", "alpha3": "mtq", "name": "Martinique"},
                {"id": 478, "alpha2": "mr", "alpha3": "mrt", "name": "Mauretanien"},
                {"id": 480, "alpha2": "mu", "alpha3": "mus", "name": "Mauritius"},
                {"id": 175, "alpha2": "yt", "alpha3": "myt", "name": "Mayotte"},
                {"id": 484, "alpha2": "mx", "alpha3": "mex", "name": "Mexiko"},
                {"id": 583, "alpha2": "fm", "alpha3": "fsm", "name": "Mikronesien"},
                {"id": 498, "alpha2": "md", "alpha3": "mda", "name": "Moldau"},
                {"id": 492, "alpha2": "mc", "alpha3": "mco", "name": "Monaco"},
                {"id": 496, "alpha2": "mn", "alpha3": "mng", "name": "Mongolei"},
                {"id": 499, "alpha2": "me", "alpha3": "mne", "name": "Montenegro"},
                {"id": 500, "alpha2": "ms", "alpha3": "msr", "name": "Montserrat"},
                {"id": 508, "alpha2": "mz", "alpha3": "moz", "name": "Mosambik"},
                {"id": 104, "alpha2": "mm", "alpha3": "mmr", "name": "Myanmar"},
                {"id": 516, "alpha2": "na", "alpha3": "nam", "name": "Namibia"},
                {"id": 520, "alpha2": "nr", "alpha3": "nru", "name": "Nauru"},
                {"id": 524, "alpha2": "np", "alpha3": "npl", "name": "Nepal"},
                {"id": 540, "alpha2": "nc", "alpha3": "ncl", "name": "Neukaledonien"},
                {"id": 554, "alpha2": "nz", "alpha3": "nzl", "name": "Neuseeland"},
                {"id": 558, "alpha2": "ni", "alpha3": "nic", "name": "Nicaragua"},
                {"id": 528, "alpha2": "nl", "alpha3": "nld", "name": "Niederlande"},
                {"id": 562, "alpha2": "ne", "alpha3": "ner", "name": "Niger"},
                {"id": 566, "alpha2": "ng", "alpha3": "nga", "name": "Nigeria"},
                {"id": 570, "alpha2": "nu", "alpha3": "niu", "name": "Niue"},
                {"id": 580, "alpha2": "mp", "alpha3": "mnp", "name": "Nördliche Marianen"},
                {"id": 807, "alpha2": "mk", "alpha3": "mkd", "name": "Nordmazedonien"},
                {"id": 574, "alpha2": "nf", "alpha3": "nfk", "name": "Norfolkinsel"},
                {"id": 578, "alpha2": "no", "alpha3": "nor", "name": "Norwegen"},
                {"id": 512, "alpha2": "om", "alpha3": "omn", "name": "Oman"},
                {"id": 40, "alpha2": "at", "alpha3": "aut", "name": "Österreich"},
                {"id": 626, "alpha2": "tl", "alpha3": "tls", "name": "Osttimor"},
                {"id": 586, "alpha2": "pk", "alpha3": "pak", "name": "Pakistan"},
                {"id": 275, "alpha2": "ps", "alpha3": "pse", "name": "Palästina"},
                {"id": 585, "alpha2": "pw", "alpha3": "plw", "name": "Palau"},
                {"id": 591, "alpha2": "pa", "alpha3": "pan", "name": "Panama"},
                {"id": 598, "alpha2": "pg", "alpha3": "png", "name": "Papua-Neuguinea"},
                {"id": 600, "alpha2": "py", "alpha3": "pry", "name": "Paraguay"},
                {"id": 604, "alpha2": "pe", "alpha3": "per", "name": "Peru"},
                {"id": 608, "alpha2": "ph", "alpha3": "phl", "name": "Philippinen"},
                {"id": 612, "alpha2": "pn", "alpha3": "pcn", "name": "Pitcairninseln"},
                {"id": 616, "alpha2": "pl", "alpha3": "pol", "name": "Polen"},
                {"id": 620, "alpha2": "pt", "alpha3": "prt", "name": "Portugal"},
                {"id": 630, "alpha2": "pr", "alpha3": "pri", "name": "Puerto Rico"},
                {"id": 638, "alpha2": "re", "alpha3": "reu", "name": "Réunion"},
                {"id": 646, "alpha2": "rw", "alpha3": "rwa", "name": "Ruanda"},
                {"id": 642, "alpha2": "ro", "alpha3": "rou", "name": "Rumänien"},
                {"id": 643, "alpha2": "ru", "alpha3": "rus", "name": "Russland"},
                {"id": 90, "alpha2": "sb", "alpha3": "slb", "name": "Salomonen"},
                {"id": 652, "alpha2": "bl", "alpha3": "blm", "name": "Saint-Barthélemy"},
                {"id": 663, "alpha2": "mf", "alpha3": "maf", "name": "Saint-Martin (französischer Teil)"},
                {"id": 894, "alpha2": "zm", "alpha3": "zmb", "name": "Sambia"},
                {"id": 882, "alpha2": "ws", "alpha3": "wsm", "name": "Samoa"},
                {"id": 674, "alpha2": "sm", "alpha3": "smr", "name": "San Marino"},
                {"id": 678, "alpha2": "st", "alpha3": "stp", "name": "São Tomé und Príncipe"},
                {"id": 682, "alpha2": "sa", "alpha3": "sau", "name": "Saudi-Arabien"},
                {"id": 752, "alpha2": "se", "alpha3": "swe", "name": "Schweden"},
                {"id": 756, "alpha2": "ch", "alpha3": "che", "name": "Schweiz"},
                {"id": 686, "alpha2": "sn", "alpha3": "sen", "name": "Senegal"},
                {"id": 688, "alpha2": "rs", "alpha3": "srb", "name": "Serbien"},
                {"id": 690, "alpha2": "sc", "alpha3": "syc", "name": "Seychellen"},
                {"id": 694, "alpha2": "sl", "alpha3": "sle", "name": "Sierra Leone"},
                {"id": 716, "alpha2": "zw", "alpha3": "zwe", "name": "Simbabwe"},
                {"id": 702, "alpha2": "sg", "alpha3": "sgp", "name": "Singapur"},
                {"id": 534, "alpha2": "sx", "alpha3": "sxm", "name": "Sint Maarten"},
                {"id": 703, "alpha2": "sk", "alpha3": "svk", "name": "Slowakei"},
                {"id": 705, "alpha2": "si", "alpha3": "svn", "name": "Slowenien"},
                {"id": 706, "alpha2": "so", "alpha3": "som", "name": "Somalia"},
                {"id": 724, "alpha2": "es", "alpha3": "esp", "name": "Spanien"},
                {"id": 144, "alpha2": "lk", "alpha3": "lka", "name": "Sri Lanka"},
                {"id": 654, "alpha2": "sh", "alpha3": "shn", "name": "St. Helena, Ascension und Tristan da Cunha"},
                {"id": 659, "alpha2": "kn", "alpha3": "kna", "name": "St. Kitts und Nevis"},
                {"id": 662, "alpha2": "lc", "alpha3": "lca", "name": "St. Lucia"},
                {"id": 666, "alpha2": "pm", "alpha3": "spm", "name": "Saint-Pierre und Miquelon"},
                {"id": 670, "alpha2": "vc", "alpha3": "vct", "name": "St. Vincent und die Grenadinen"},
                {"id": 710, "alpha2": "za", "alpha3": "zaf", "name": "Südafrika"},
                {"id": 729, "alpha2": "sd", "alpha3": "sdn", "name": "Sudan"},
                {"id": 239, "alpha2": "gs", "alpha3": "sgs", "name": "Südgeorgien und die Südlichen Sandwichinseln"},
                {"id": 728, "alpha2": "ss", "alpha3": "ssd", "name": "Südsudan"},
                {"id": 740, "alpha2": "sr", "alpha3": "sur", "name": "Suriname"},
                {"id": 744, "alpha2": "sj", "alpha3": "sjm", "name": "Spitzbergen und Jan Mayen"},
                {"id": 760, "alpha2": "sy", "alpha3": "syr", "name": "Syrien"},
                {"id": 762, "alpha2": "tj", "alpha3": "tjk", "name": "Tadschikistan"},
                {"id": 158, "alpha2": "tw", "alpha3": "twn", "name": "Republik China"},
                {"id": 834, "alpha2": "tz", "alpha3": "tza", "name": "Tansania"},
                {"id": 764, "alpha2": "th", "alpha3": "tha", "name": "Thailand"},
                {"id": 768, "alpha2": "tg", "alpha3": "tgo", "name": "Togo"},
                {"id": 772, "alpha2": "tk", "alpha3": "tkl", "name": "Tokelau"},
                {"id": 776, "alpha2": "to", "alpha3": "ton", "name": "Tonga"},
                {"id": 780, "alpha2": "tt", "alpha3": "tto", "name": "Trinidad und Tobago"},
                {"id": 148, "alpha2": "td", "alpha3": "tcd", "name": "Tschad"},
                {"id": 203, "alpha2": "cz", "alpha3": "cze", "name": "Tschechien"},
                {"id": 788, "alpha2": "tn", "alpha3": "tun", "name": "Tunesien"},
                {"id": 792, "alpha2": "tr", "alpha3": "tur", "name": "Türkei"},
                {"id": 795, "alpha2": "tm", "alpha3": "tkm", "name": "Turkmenistan"},
                {"id": 796, "alpha2": "tc", "alpha3": "tca", "name": "Turks- und Caicosinseln"},
                {"id": 798, "alpha2": "tv", "alpha3": "tuv", "name": "Tuvalu"},
                {"id": 800, "alpha2": "ug", "alpha3": "uga", "name": "Uganda"},
                {"id": 804, "alpha2": "ua", "alpha3": "ukr", "name": "Ukraine"},
                {"id": 348, "alpha2": "hu", "alpha3": "hun", "name": "Ungarn"},
                {"id": 581, "alpha2": "um", "alpha3": "umi", "name": "United States Minor Outlying Islands"},
                {"id": 858, "alpha2": "uy", "alpha3": "ury", "name": "Uruguay"},
                {"id": 860, "alpha2": "uz", "alpha3": "uzb", "name": "Usbekistan"},
                {"id": 548, "alpha2": "vu", "alpha3": "vut", "name": "Vanuatu"},
                {"id": 336, "alpha2": "va", "alpha3": "vat", "name": "Vatikanstadt"},
                {"id": 862, "alpha2": "ve", "alpha3": "ven", "name": "Venezuela"},
                {"id": 784, "alpha2": "ae", "alpha3": "are", "name": "Vereinigte Arabische Emirate"},
                {"id": 840, "alpha2": "us", "alpha3": "usa", "name": "Vereinigte Staaten"},
                {"id": 826, "alpha2": "gb", "alpha3": "gbr", "name": "Vereinigtes Königreich"},
                {"id": 704, "alpha2": "vn", "alpha3": "vnm", "name": "Vietnam"},
                {"id": 876, "alpha2": "wf", "alpha3": "wlf", "name": "Wallis und Futuna"},
                {"id": 162, "alpha2": "cx", "alpha3": "cxr", "name": "Weihnachtsinsel"},
                {"id": 732, "alpha2": "eh", "alpha3": "esh", "name": "Westsahara"},
                {"id": 140, "alpha2": "cf", "alpha3": "caf", "name": "Zentralafrikanische Republik"},
                {"id": 196, "alpha2": "cy", "alpha3": "cyp", "name": "Zypern"}],
            submitSuccess: undefined,
            submitError: null,
            validationErrors: {
                fields: {},
                hints: [],
                message: null,
            },
        };
    },

    methods: {
        close() {
            this.$emit('modal-close');
        },
        nextStep() {
            if (this.validateStep()) {
                this.step++;
            }
        },
        previousStep() {
            this.resetValidation();
            this.step--;
            this.submitSuccess = undefined;
        },

        resetValidation() {
            this.validationErrors = {
                fields: {
                    positions: []
                },
                hints: [],
                message: null,
                validated: false,
                valid: true,
            }
        },

        validateStep() {
            this.resetValidation();
            switch (this.step) {
                case 1: // Address
                    if (!(this.order.customer.first_name && this.order.customer.last_name) && !this.order.customer.company) {
                        this.validationErrors.fields.first_name = this.validationErrors.fields.last_name = this.validationErrors.fields.company = true;
                        this.validationErrors.hints.push('Firma oder Vor- und Nachname fehlt');
                        this.validationErrors.valid = false;
                    }
                    if (!this.order.customer.address || !/\d+/.test(this.order.customer.address)) {
                        this.validationErrors.fields.address = true;
                        this.validationErrors.hints.push('Straße oder Hausnummer fehlt');
                        this.validationErrors.valid = false;
                    }
                    if (!this.order.customer.postal_code) {
                        this.validationErrors.fields.postal_code = true;
                        this.validationErrors.hints.push('PLZ fehlt');
                        this.validationErrors.valid = false;
                    }
                    if (!this.order.customer.city) {
                        this.validationErrors.fields.city = true;
                        this.validationErrors.hints.push('Ort fehlt')
                        this.validationErrors.valid = false;
                    }
                    if (!this.validationErrors.valid) {
                        this.validationErrors.message = "Adressfehler";
                    }
                    break;
                case 2: // positions
                    if (!this.order.positions.length) {
                        this.validationErrors.hints.push(`Wähle mindestens einen Artikel aus.`);
                        this.validationErrors.valid = false;
                    }

                    for (let i = 0; i < this.order.positions.length; i++) {
                        if (!this.order.positions[i].product) {
                            this.validationErrors.fields.positions[i] = true;
                            this.validationErrors.hints.push(`Position ${i + 1}: Artikel auswählen`);
                            this.validationErrors.valid = false;
                        }

                        if (!this.order.positions[i].quantity
                            || this.order.positions[i].quantity < 1) {
                            this.validationErrors.fields.positions[i] = true;
                            this.validationErrors.hints.push(`Position ${i + 1}: Stückzahl ungültig`);
                            this.validationErrors.valid = false;
                        }

                        if (this.order.positions[i].product
                            && this.order.positions[i].quantity > this.products.find((product) => product.article == this.order.positions[i].product)?.available
                        ) {
                            this.validationErrors.fields.positions[i] = true;
                            this.validationErrors.hints.push(`Bestellmenge Position ${i + 1} zu groß (max. ${this.products.find((product) => product.article == this.order.positions[i].product).available})`);
                            this.validationErrors.valid = false;
                        }
                    }
                    break;
            }
            this.validationErrors.validated = true;
            return this.validationErrors.valid;
        },

        validateAgain() {
            if (this.validationErrors.validated) {
                this.validateStep();
            }
        },

        addPosition(index) {
            if (index === undefined) {
                this.order.positions.push({quantity: 1});
            } else {
                this.order.positions.splice(index, 0, {quantity: 1})
            }
        },
        removePosition(index) {
            this.order.positions.splice(index, 1);
        },
        changeArticle(index) {
            this.order.positions[index].available = this.products.find(element => element.id == this.order.positions[index].product).available;
            if (this.order.positions[index].quantity > this.order.positions[index].available) {
                this.order.positions[index].quantity = this.order.positions[index].available;
            }
        },

        submitOrder() {
            this.step = 4;
            axios.post('/api/orders', {
                order: this.order
            }).then(response => {
                if (response.status > 400) {
                    this.submitSuccess = false;
                    this.submitError = {
                        message: "Ein unbekannter Fehler ist aufgetreten. Kontaktieren Sie bitte Ihren Ansprechpartner."
                    };

                    return;
                }
                this.submitSuccess = true;
            }).catch(error => {
                this.submitSuccess = false;
                this.submitError = error.toJson();
            })
        },

        reformatProducts() {
            this.searchProducts = [];
            for (let product of this.products) {
                this.searchProducts.push({
                    label: `${product.name} (${product.available}) - GTIN ${product.gtin} - ${product.id}`,
                    value: product.article,
                });
            }
        },

        fetchProducts() {
            axios.get('/api/orders/products')
                .then((response) => {
                    this.products = response.data.data;
                    this.reformatProducts();
                })
                .catch(error => {
                    console.log(error)
                });
        },

        fetchShippingMethods() {
            axios.get('/api/orders/delivery-methods')
                .then((response) => {
                    this.shippingMethods = response.data.data;
                })
                .catch(error => {
                    console.log(error)
                });
        }
    },

    watch: {
        'order.positions': {
            handler(newValue, oldValue) {
                this.validateStep();
            },
            deep: true
        }
    },

    mounted() {
        this.fetchProducts();
        this.fetchShippingMethods();
    },
}
</script >
