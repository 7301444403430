<template >
    <div v-if="loadingSpinner"
         class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-slate-600 flex flex-col items-center justify-center opacity-70" >
        <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4" ></div >
        <h2 class="text-center text-white text-xl font-semibold" >Wird geladen...</h2 >
        <p class="w-1/3 text-center text-white" >Dies kann wenige Sekunden dauern. Bitte die Seite nicht schließen.</p >
    </div >

    <div >
        <div >
            <div class="text-xl text-gray-600 mt-4 mb-4" >Meldungen</div >

            <div class="flex flex-wrap space-x-0 lg:space-x-5 space-y-5 lg:space-y-0 items-center" >
                <router-link to="/orders/ueberbestellung"
                             class="rounded bg-slate-100 p-4 flex flex-row w-full lg:flex-1 cursor-pointer items-center hover:shadow transition-all" >
                    <div class="flex flex-col flex-1" >
                        <div class="flex flex-row items-center" >
                            <div class="p-2 bg-error-over-ordering rounded mr-6" >
                                <ExclamationCircleIcon class="text-white h-7" />
                            </div >

                            <div class="flex flex-col justify-center" >
                                <div class="text-gray-600 text-lg leading-6" >
                                    Überbestellungen
                                </div >
                                <div class="font-semibold text-xl leading-tight" >
                                    {{ notifications.overOrdering }}
                                </div >
                            </div >
                        </div >
                    </div >

                    <div >
                        <ArrowRightIcon class="h-5" />
                    </div >
                </router-link >

                <router-link to="/orders/adressfehler"
                             class="rounded bg-slate-100 p-4 flex flex-row w-full lg:flex-1 cursor-pointer items-center hover:shadow transition-all" >
                    <div class="flex flex-col flex-1" >
                        <div class="flex flex-row items-center" >
                            <div class="p-2 bg-yellow-400 rounded mr-6" >
                                <ExclamationCircleIcon class="text-white h-7" />
                            </div >

                            <div class="flex flex-col justify-center" >
                                <div class="text-gray-600 text-lg leading-6" >
                                    Adressfehler
                                </div >
                                <div class="font-semibold text-xl leading-tight" >
                                    {{ notifications.addressError }}
                                </div >
                            </div >
                        </div >
                    </div >

                    <div >
                        <ArrowRightIcon class="h-5" />
                    </div >
                </router-link >

                <router-link to="/orders/zuweisung"
                             class="rounded bg-slate-100 p-4 flex flex-row w-full lg:flex-1 cursor-pointer items-center hover:shadow transition-all" >
                    <div class="flex flex-col flex-1" >
                        <div class="flex flex-row items-center" >
                            <div class="p-2 bg-violet-400 rounded mr-6" >
                                <ExclamationCircleIcon class="text-white h-7" />
                            </div >

                            <div class="flex flex-col justify-center" >
                                <div class="text-gray-600 text-lg leading-6" >
                                    Zuweisungsfehler
                                </div >
                                <div class="font-semibold text-xl leading-tight" >
                                    {{ notifications.assignmentError }}
                                </div >
                            </div >
                        </div >
                    </div >

                    <div >
                        <ArrowRightIcon class="h-5" />
                    </div >
                </router-link >
            </div >
        </div >

        <div class="mt-8" >
            <div class="" >
                <div class="text-xl text-gray-600 mb-4" >Prüfungen</div >

                <div class="flex flex-wrap space-x-0 lg:space-x-5 space-y-5 lg:space-y-0 items-center" >
                    <router-link to="/orders/offen"
                                 class="rounded bg-slate-100 p-4 flex flex-row w-full lg:flex-1 cursor-pointer items-center hover:shadow transition-all" >
                        <div class="flex flex-col flex-1" >
                            <div class="flex flex-row items-center" >
                                <div class="p-2 bg-secondary rounded mr-6" >
                                    <CheckCircleIcon class="text-white h-7" />
                                </div >

                                <div class="flex flex-col justify-center" >
                                    <div class="text-gray-600 text-lg leading-6" >
                                        Offene Aufträge
                                    </div >
                                    <div class="font-semibold text-xl leading-tight" >
                                        {{ checks.openOrders }}
                                    </div >
                                </div >
                            </div >
                        </div >

                        <div >
                            <ArrowRightIcon class="h-5" />
                        </div >
                    </router-link >

                    <router-link to="/rma/ungelesen"
                                 class="rounded bg-slate-100 p-4 flex flex-row w-full lg:flex-1 cursor-pointer items-center hover:shadow transition-all" >
                        <div class="flex flex-col flex-1" >
                            <div class="flex flex-row items-center" >
                                <div class="p-2 bg-secondary rounded mr-6" >
                                    <CheckCircleIcon class="text-white h-7" />
                                </div >

                                <div class="flex flex-col justify-center" >
                                    <div class="text-gray-600 text-lg leading-6" >
                                        Neue Retouren
                                    </div >
                                    <div class="font-semibold text-xl leading-tight" >
                                        {{ checks.newRmas }}
                                    </div >
                                </div >
                            </div >
                        </div >

                        <div >
                            <ArrowRightIcon class="h-5" />
                        </div >
                    </router-link >

                    <router-link to="/goods/in_bearbeitung"
                                 class="rounded bg-slate-100 p-4 flex flex-row w-full lg:flex-1 cursor-pointer items-center hover:shadow transition-all" >
                        <div class="flex flex-col flex-1" >
                            <div class="flex flex-row items-center" >
                                <div class="p-2 bg-secondary rounded mr-6" >
                                    <CheckCircleIcon class="text-white h-7" />
                                </div >

                                <div class="flex flex-col justify-center" >
                                    <div class="text-gray-600 text-lg leading-6" >
                                        Neue Lieferungen
                                    </div >
                                    <div class="font-semibold text-xl leading-tight" >
                                        {{ checks.newGoods }}
                                    </div >
                                </div >
                            </div >
                        </div >

                        <div >
                            <ArrowRightIcon class="h-5" />
                        </div >
                    </router-link >
                </div >
            </div >
        </div >

        <div class="mt-8" >
            <div class="" >
                <div class="text-xl text-gray-600 mb-4" >Aufgaben</div >

                <div class="flex flex-wrap space-x-0 lg:space-x-5 space-y-5 lg:space-y-0 items-center" >
                    <!--                    <Tasks />-->
                    <img src="/images/tasks_placeholder.svg" alt="Aufgaben: Bald verfügbar" class="w-full" >
                </div >
            </div >
        </div >

        <div class="mt-8" >
            <div class="" >
                <div class="text-xl text-gray-600 mb-4" >Schnellaktionen</div >

                <div class="flex flex-wrap space-x-0 lg:space-x-5 space-y-5 lg:space-y-0 items-center" >
                    <div @click="this.quickActions.showCreateOrder = true"
                         class="rounded bg-slate-100 p-4 flex flex-row w-full lg:flex-1 cursor-pointer items-center hover:shadow transition-all" >
                        <div class="flex flex-col flex-1" >
                            <div class="flex flex-row items-center" >
                                <div class="p-2 bg-red-400 rounded mr-6" >
                                    <PlusCircleIcon class="text-white h-7" />
                                </div >

                                <div class="flex flex-col justify-center" >
                                    <div class="text-gray-600 text-lg leading-6" >
                                        Auftrag anlegen
                                    </div >
                                </div >
                            </div >
                        </div >

                        <div >
                            <ArrowRightIcon class="h-5" />
                        </div >
                    </div >

                    <div @click="this.quickActions.showCreateGoods = true"
                         class="rounded bg-slate-100 p-4 flex flex-row w-full lg:flex-1 cursor-pointer items-center hover:shadow transition-all" >
                        <div class="flex flex-col flex-1" >
                            <div class="flex flex-row items-center" >
                                <div class="p-2 bg-red-400 rounded mr-6" >
                                    <PlusCircleIcon class="text-white h-7" />
                                </div >

                                <div class="flex flex-col justify-center" >
                                    <div class="text-gray-600 text-lg leading-6" >
                                        Lieferung anmelden
                                    </div >
                                </div >
                            </div >
                        </div >

                        <div >
                            <ArrowRightIcon class="h-5" />
                        </div >
                    </div >

                    <a :href="`mailto:support@beckmannsys.com`"
                       class="rounded bg-slate-100 p-4 flex flex-row w-full lg:flex-1 cursor-pointer items-center hover:shadow transition-all" >
                        <div class="flex flex-col flex-1" >
                            <div class="flex flex-row items-center" >
                                <div class="p-2 bg-red-400 rounded mr-6" >
                                    <ChatBubbleBottomCenterTextIcon class="text-white h-7" />
                                </div >

                                <div class="flex flex-col justify-center" >
                                    <div class="text-gray-600 text-lg leading-6" >
                                        Nachricht ans Team
                                    </div >
                                </div >
                            </div >
                        </div >

                        <div >
                            <ArrowRightIcon class="h-5" />
                        </div >
                    </a >
                </div >
            </div >
        </div >
    </div >

    <Modal :show="this.quickActions.showCreateOrder" @modalClose="this.quickActions.showCreateOrder = false" >
        <template v-slot:body >
            <OrderCreate @closeMe="this.quickActions.showCreateOrder = false" />
        </template >
    </Modal >

    <Modal :show="this.quickActions.showCreateGoods" @modalClose="this.quickActions.showCreateGoods = false" >
        <template v-slot:body >
            <GoodsCreate @closeMe="this.quickActions.showCreateGoods = false" />
        </template >
    </Modal >
</template >

<script >
import {
    ArrowRightIcon,
    ChatBubbleBottomCenterTextIcon,
    CheckCircleIcon,
    ExclamationCircleIcon,
    PlusCircleIcon
} from "@heroicons/vue/24/outline";
import Modal from "../components/Modal.vue";
import OrderCreate from "../components/OrderCreate.vue";
import GoodsCreate from "../components/GoodsCreate.vue";
import Tasks from "../components/Tasks.vue";

export default {
    components: {
        GoodsCreate,
        OrderCreate, Modal,
        ExclamationCircleIcon,
        ArrowRightIcon,
        CheckCircleIcon,
        PlusCircleIcon,
        ChatBubbleBottomCenterTextIcon,
        Tasks,
    },

    data() {
        return {
            loadingSpinner: false,
            notifications: {
                overOrdering: '-',
                addressError: '-',
                assignmentError: '-',
            },
            checks: {
                openOrders: '-',
                newRmas: '-',
                newGoods: '-',
            },
            quickActions: {
                showCreateOrder: false,
                showCreateGoods: false,
                showNotifyTeamModal: false,
            },
        };
    },

    computed: {
        fetchUrl() {
            return `/api/dashboard`;
        },
    },

    methods: {
        fetchData() {
            this.loadingSpinner = true;
            axios.get(this.fetchUrl)
                .then(response => {
                    this.notifications = response.data.data.notifications;
                    this.checks = response.data.data.checks;

                    this.submitSuccess = true;
                    this.$emit('close-me');
                })
                .catch(error => {
                    this.$swal({
                        title: 'Fehler beim Laden der Daten',
                        text: error.message,
                        icon: 'error',
                        showConfirmButton: true,
                        confirmButtonText: 'OK',
                    });
                })
                .finally(() => {
                    this.loadingSpinner = false;
                });
        },
    },

    mounted() {
        this.fetchData();
    }
}
</script >
