<template>
    {{ formattedDate ?? '-' }}
</template>

<script>
export default {
    name: "FormatDate.vue",
    props: {
        'date': {
            required: true,
        },
    },

    computed: {
        formattedDate() {
            if (!this.date) {
                return null;
            }

            return new Date(this.date).toLocaleDateString('de-DE',
                {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                }
            );
        }
    },
}
</script>
