<template>
    <div class="relative h-[400px]">
    <Line v-if="chartData"
          ref="chart"
        :options="chartOptions"
        :data="chartData"
    />
    </div>
</template>

<script>
import { Line } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement, Filler, TimeScale} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement, Filler, TimeScale)

import 'chartjs-adapter-moment';

export default {
    components: {
        Line
    },

    props: {
        dataset: {
            type: Object,
        },
    },

    data() {
        return {
            chartOptions: {
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top'
                    },
                },
                scales: {
                    x: {
                        type: 'time',
                        time: {
                            tooltipFormat: 'DD.MM.YYYY',
                            unit: 'month',
                        },
                        title: {
                            display: true,
                        },
                        grid: {
                            display: false,
                            drawOnChartArea: false,
                        }
                    },
                    y: {
                        position: 'right',
                    }
                }
            },
        }
    },

    computed: {
        chartData() {
            return {
                datasets: [
                    {
                        label: "Aufträge",
                        data: this.dataset['orders'],
                        borderColor: '#06B6D4',
                        backgroundColor: 'rgba(6, 182, 212, 0.05)',
                        fill: 'origin',
                        tension: 0.4,
                    },
                    {
                        label: "Artikel",
                        data: this.dataset['articles'],
                        borderColor: '#DB4538',
                        backgroundColor: 'rgba(255, 149, 0, 0.05)',
                        fill: 'origin',
                        tension: 0.4,
                    },
                ],
            };
        }
    },
}
</script>
