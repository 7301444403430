<template>
    <form>
        <div class="text-gray-900 flex mb-5">
            <div class="text-lg font-medium ">
                Benutzerdetails
            </div>
        </div>

        <div class="flex flex-col justify-between h-full">
            <div v-if="step == 0 || !editMode" class="flex-1">
                <div v-if="editMode && validationErrors.hints.length">
                    <ul v-if="validationErrors.hints.length"
                        class="list-disc list-inside grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-x-4 text-primary-dark">
                        <li v-for="hint in validationErrors.hints">{{ hint }}</li>
                    </ul>
                </div>

                <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">

                    <div>
                        <label for="username" class="block text-sm font-medium text-gray-700">Benutzername</label>
                        <div class="mt-1">
                            <input v-model="user.username" type="text" id="username" name="username"
                                   autocomplete="off"
                                   :disabled="!editMode"
                                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-indigo-500 sm:text-sm"
                                   :class="validationErrors.fields.username ? ['!border-primary'] : []"
                                   @change="validateAgain"/>
                        </div>
                    </div>
                    <div>
                        <label for="email" class="block text-sm font-medium text-gray-700">E-Mail</label>
                        <div class="mt-1">
                            <input v-model="user.email" type="text" id="email" name="email"
                                   autocomplete="off"
                                   :disabled="!editMode"
                                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                   :class="validationErrors.fields.email ? ['!border-primary'] : []"
                                   @change="validateAgain"/>
                        </div>
                    </div>
                    <div v-if="editMode">
                        <label for="password" class="block text-sm font-medium text-gray-700">Passwort</label>
                        <div class="mt-1">
                            <input v-model="user.password" type="password" id="password" name="password"
                                   autocomplete="off"
                                   :disabled="!editMode"
                                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                   :class="validationErrors.fields.password ? ['!border-primary'] : []"
                                   @change="validateAgain"/>
                        </div>
                    </div>
                    <div v-if="editMode">
                        <label for="passwordRepeat" class="block text-sm font-medium text-gray-700">Passwort
                            Wiederholung</label>
                        <div class="mt-1">
                            <input v-model="user.passwordRepeat" type="password" id="passwordRepeat"
                                   name="passwordRepeat"
                                   autocomplete="off"
                                   :disabled="!editMode"
                                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                   :class="validationErrors.fields.passwordRepeat ? ['!border-primary'] : []"
                                   @change="validateAgain"/>
                        </div>
                    </div>
                    <div class="col-span-full">
                        <label for="customers" class="block text-sm font-medium text-gray-700">Kunden</label>
                        <div class="mt-1">
                            <Multiselect v-model="user.customers"
                                         mode="tags"
                                         :disabled="!editMode"
                                         searchable="true"
                                         tags="true"
                                         :options="selectOptions.customers"
                                         :showNoResults="true"
                                         @change="validateAgain"
                            >
                                <template v-slot:noResult>
                                    Keine Einträge gefunden
                                </template>
                            </Multiselect>
                        </div>
                    </div>
                    <div class="col-span-full">
                        <label for="categories" class="block text-sm font-medium text-gray-700">Kategorien</label>
                        <div class="mt-1">
                            <Multiselect v-model="user.categories"
                                         mode="tags"
                                         :disabled="!editMode"
                                         searchable="true"
                                         :options="selectOptions.categories"
                                         :showNoResults="true"
                                         @change="validateAgain"
                            >
                                <template v-slot:noResult>
                                    Keine Einträge gefunden
                                </template>
                            </Multiselect>
                        </div>
                    </div>
                    <div class="col-span-full">
                        <label for="deliveryMethods" class="block text-sm font-medium text-gray-700">Versandarten</label>
                        <div class="mt-1">
                            <Multiselect v-model="user.deliveryMethods"
                                         mode="tags"
                                         searchable="true"
                                         :options="selectOptions.deliveryMethods"
                                         :showNoResults="true"
                                         @change="validateAgain"
                            >
                                <template v-slot:noResult>
                                    Keine Einträge gefunden
                                </template>
                            </Multiselect>
                        </div>
                    </div>
                    <div>
                        <label for="role" class="block text-sm font-medium text-gray-700">Level</label>
                        <div class="mt-1">
                            <Multiselect v-model="user.level"
                                         mode="single"
                                         :disabled="!editMode"
                                         :options="selectOptions.roles"
                                         @change="validateAgain"
                            >
                                <template v-slot:noResult>
                                    Keine Einträge gefunden
                                </template>
                            </Multiselect>
                        </div>
                    </div>
                    <div>
                        <label for="status" class="block text-sm font-medium text-gray-700">Status</label>
                        <div class="mt-1">
                            <Multiselect v-model="user.status"
                                         mode="single"
                                         :options="selectOptions.status"
                                         @change="validateAgain"
                            >
                                <template v-slot:noResult>
                                    Keine Einträge gefunden
                                </template>
                            </Multiselect>
                        </div>
                    </div>

                    <div>
                        <label for="dhl_invoices" class="block text-sm font-medium text-gray-700">DHL Rechnungen</label>
                        <div class="mt-1">
                            <input v-model="user.dhl_invoices" type="text" id="dhl_invoices" name="dhl_invoices"
                                   autocomplete="off"
                                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="step == 1" class="flex-1">
                <div v-if="submitSuccess === true" class="text-center">
                    <div class="mx-auto flex mt-16 mb-16 h-20 w-20 items-center justify-center rounded-full bg-green-100">
                        <CheckIcon class="h-16 w-16 text-green-600" aria-hidden="true"/>
                    </div>
                    <div class="mt-4 text-3xl">Übermittlung erfolgreich</div>
                    <div class="mt-4">Benutzer {{ user.username }} erfolgreich geändert.</div>
                </div>
                <div v-else-if="submitSuccess === false" class="text-center">
                    <div class="mx-auto flex mt-16 mb-16 h-20 w-20 items-center justify-center rounded-full bg-red-100">
                        <ExclamationTriangleIcon class="h-16 w-16 text-red-600" aria-hidden="true"/>
                    </div>
                    <div class="mt-4 text-3xl">Übermittlung fehlgeschlagen</div>
                    <div class="mt-4">Der Benutzer {{ user.username }} konnte nicht geändert werden werden.</div>
                    <div class="mt-4">Fehlermeldung:</div>
                    <div class="mt-4">{{ submitError.message }}</div>
                </div>
                <div v-else class="text-center">
                    <div class="mx-auto flex mt-16 mb-16 h-20 w-20 items-center justify-center rounded-full bg-gray-100">
                        <InformationCircleIcon class="h-16 w-16 text-gray-600" aria-hidden="true"/>
                    </div>
                    <div class="mt-4 text-3xl">Benutzer wird geändert</div>
                </div>
            </div>
        </div>
        <div class="mt-8 flex"
             :class="!editMode || step === 0 || (step == 1 && submitSuccess === true) ? 'justify-end' : 'justify-between'">
            <button type="button"
                    v-if="!editMode || step == 0"
                    @click="confirmDelete"
                    class="mr-3 rounded-md border border-primary bg-white py-2 px-4 text-sm text-primary shadow-sm hover:bg-white bg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50">
                <TrashIcon class="h-5 inline-block mr-1"/>
                Löschen
            </button>
            <button type="button"
                    v-if="!editMode || (step == 1 && submitSuccess == true)"
                    @click="close"
                    class="rounded-md border border-transparent bg-secondary py-2 px-4 text-sm text-white shadow-sm hover:bg-secondary-dark focus:outline-none focus:ring-2 focus:bg-secondary-dark focus:ring-offset-2 focus:ring-offset-gray-50">
                Schließen
            </button>
            <button type="button"
                    v-if="step == 1 && submitSuccess === false"
                    @click="step = 0"
                    class="rounded-md border border-transparent bg-secondary py-2 px-4 text-sm text-white shadow-sm hover:bg-secondary-dark focus:outline-none focus:ring-2 focus:bg-secondary-dark focus:ring-offset-2 focus:ring-offset-gray-50">
                Zurück
            </button>
            <button type="button"
                    v-if="editMode && step == 0"
                    @click="updateUser"
                    class="rounded-md border border-transparent bg-primary py-2 px-4 text-sm text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:bg-primary-dark focus:ring-offset-2 focus:ring-offset-gray-50">
                Speichern
            </button>
            <button type="button"
                    v-if="step == 1 && submitSuccess === false"
                    @click="close"
                    class="rounded-md border border-transparent bg-primary py-2 px-4 text-sm text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:bg-primary-dark focus:ring-offset-2 focus:ring-offset-gray-50">
                Abbrechen
            </button>
        </div>
    </form>
</template>

<script>
import {
    PlusIcon,
    TrashIcon,
    ExclamationTriangleIcon,
    CheckIcon,
    InformationCircleIcon,
    PencilSquareIcon
} from '@heroicons/vue/24/outline'
import Steps from './Steps.vue'
import Multiselect from '@vueform/multiselect'


export default {
    components: {
        PlusIcon,
        TrashIcon,
        CheckIcon,
        ExclamationTriangleIcon,
        InformationCircleIcon,
        PencilSquareIcon,
        Steps,
        Multiselect,
    },

    props: [
        "userId",
    ],

    data() {
        return {
            selectOptions: {
                customers: [],
                categories: [],
                deliveryMethods: [],
                roles: [
                    {value: 'customer', label: 'Kunde'},
                    {value: 'admin', label: 'Administrator'}
                ],
                status: [
                    {value: 'active', label: 'Aktiv'},
                    {value: 'inactive', label: 'Inaktiv'}
                ],
            },
            submitSuccess: undefined,
            step: 0,
            submitError: null,
            validationErrors: {
                fields: {},
                hints: [],
                message: null,
            },
            user: {
                "customers": [],
                "categories": [],
                "deliveryMethods": [],
                "dhl_invoices": '',
                "status": '',
                "level": '',
                "username": '',
                "email": '',
            },
            editMode: true,
        };
    },

    methods: {
        close() {
            this.$emit('close-me');
        },
        nextStep() {
            if (this.validateStep()) {
                this.step++;
            }
        },
        previousStep() {
            this.resetValidation();
            this.step--;
            this.submitSuccess = undefined;
        },

        resetValidation() {
            this.validationErrors = {
                fields: {
                    positions: []
                },
                hints: [],
                message: null,
                validated: false,
                valid: true,
            }
        },

        validate() {
            this.resetValidation();
            if (!this.user.username) {
                this.validationErrors.fields.username = true;
                this.validationErrors.hints.push('Benutzername fehlt');
                this.validationErrors.valid = false;
            }
            if (!this.user.email) {
                this.validationErrors.fields.email = true;
                this.validationErrors.hints.push('E-Mail-Adresse fehlt');
                this.validationErrors.valid = false;
            }
            if ((this.user.password || this.user.passwordRepeat) && (this.user.password != this.user.passwordRepeat)) {
                this.validationErrors.fields.password = this.validationErrors.fields.passwordRepeat = true;
                this.validationErrors.hints.push('Passwort stimmt nicht überein');
                this.validationErrors.valid = false;
            }
            if (!this.user.level) {
                this.validationErrors.fields.role = true;
                this.validationErrors.hints.push('Benutzerrolle fehlt')
                this.validationErrors.valid = false;
            }
            this.validationErrors.validated = true;
            return this.validationErrors.valid;
        },

        validateAgain() {
            if (this.validationErrors.validated) {
                this.validate();
            }
        },

        updateUser() {
            if (this.validate()) {
                this.submitSuccess = undefined;
                this.step = 1;
                axios.post('/api/users/' + this.user?.id, this.user)
                        .then(response => {
                            this.submitSuccess = true;
                        })
                        .catch(error => {
                            this.submitSuccess = false;
                            this.submitError = error.toJSON();
                        });
            }
        },

        async confirmDelete() {
            try {
                let confirmation = await this.$swal({
                    text:`Benutzer ${this.user.username} wirklich löschen?`,
                    icon: 'warning',
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Löschen',
                    cancelButtonText: 'Abbrechen',
                })

                if (confirmation.isConfirmed) {
                    let response = await axios.delete('/api/users/' + this.user?.id);
                    this.$swal({
                        text: `Benutzer ${this.user.username} gelöscht`,
                        icon: 'success',
                        showConfirmButton: true,
                        confirmButtonText: 'OK',
                    });
                    this.$emit('close-me');
                }
            } catch (error) {
                this.$swal({
                    title: 'Fehler',
                    text: error.message,
                    showConfirmButton: true,
                });
            }
        },

        fetchCustomers() {
            axios.get('/api/users/customers')
                .then(response => {
                    this.selectOptions.customers = response.data.data;
                })
                .catch(error => {
                    console.log("Error fetching customers", error);
                });
        },

        fetchDeliveryMethods() {
            axios.get('/api/users/delivery-methods')
                .then(response => {
                    this.selectOptions.deliveryMethods = response.data.data;
                })
                .catch(error => {
                    console.log("Error fetching delivery methods", error);
                });
        },

        fetchCategories() {
            axios.get('/api/users/categories')
                .then(response => {
                    this.selectOptions.categories = response.data.data;
                })
                .catch(error => {
                    console.log("Error fetching categories", error);
                });
        }
    },

    mounted() {
        this.fetchCustomers();
        this.fetchDeliveryMethods();
        this.fetchCategories();

        axios.get('/api/users/' + this.userId)
            .then(response => {
                this.user = response.data.data;
            })
            .catch(error => {
                console.log("Error fetching users", error);
            });
    },
}
</script>
