import axios from "axios";
import Cookies from "js-cookie";

const customAxios = axios.create({
    baseURL: "/",
});

customAxios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        Cookies.remove('level', { path: '' })
        Cookies.remove('token', { path: '' })
        Cookies.remove('tags', { path: '' })
        location.href = '/';
    } else {
        return error;
    }
});


export default customAxios;
