<template >
    <div >
        <div class="text-xl font-medium mb-5" >
            {{ task.subject }}
        </div >

        <div class="space-y-2" >
            <div class="flex space-x-3" >
                <div class="text-sm font-medium text-gray-500" >
                    Datum:
                </div >
                <div class="text-sm" >
                    <div v-if="isCustomer" >{{ moment(task.created_at).format('DD.MM.YYYY') }}
                    </div >
                    <div v-else >
                        {{ moment(task.created_at).format('DD.MM.YYYY HH:mm') }} –
                        <span class="font-medium" >{{ moment(task.created_at).fromNow() }}</span >
                    </div >
                </div >
            </div >

            <div class="flex space-x-3" v-if="!isCustomer" >
                <div class="text-sm font-medium text-gray-500" >
                    Letzte Aktualisierung:
                </div >
                <div class="text-sm" >
                    <div >
                        {{ moment(task.updated_at).format('DD.MM.YYYY HH:mm') }} –
                        <span class="font-medium" >{{ task.updated_by }}</span >
                    </div >
                </div >
            </div >

            <div class="flex space-x-3" >
                <div class="text-sm font-medium text-gray-500" >
                    Status:
                </div >
                <div class="text-sm" >
                    <span
                        :class="['px-2 py-1 text-xs font-medium rounded-full', statusBadgeClass(task.status)]" >
                        {{ translatedStatus(task.status) }}
                    </span >
                </div >
            </div >

            <div class="flex flex-col space-y-3 !mt-5" >
                <div class="text-sm font-medium text-gray-500" >
                    <span v-if="isCustomer" >Deine Details</span >
                    <span v-else >Kunden Details</span >
                </div >
                <div class="text-sm" >
                    <textarea v-model="task.client_information" rows="2" disabled
                              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div >
            </div >

            <div class="flex flex-col space-y-3 !mt-5" >
                <div class="text-sm font-medium text-gray-500" >
                    Kommentar Team
                </div >
                <div class="text-sm" >
                    <textarea v-model="task.team_comment" rows="2" disabled
                              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div >
            </div >
        </div >

    </div >
</template >

<script >
import moment from "moment";

export default {
    props: {
        task: {
            type: Object,
            required: true
        }
    },
    computed: {
        isCustomer() {
            return window.isCustomer();
        },

        moment() {
            return moment;
        },
    },

    methods: {
        statusBadgeClass(status) {
            return {
                'bg-slate-200 text-slate-800': status === 'created',
                'bg-green-200 text-green-800': status === 'completed',
                'bg-red-200 text-red-800': status === 'cancelled',
            }
        },

        translatedStatus(status) {
            return {
                'created': 'Erstellt',
                'completed': 'Erfolgreich',
                'cancelled': 'Abgebrochen',
            }[status];
        },
    },
}
</script >
