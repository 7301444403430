<template >
    <div class="" >
        <div class="sm:flex sm:items-center" >
            <div class="sm:flex-auto" >
                <h1 class="text-base font-medium leading-6 text-gray-900" >Tags verwalten</h1 >
            </div >
            <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none" >
                <button type="button" @click="createTag" v-if="tags.length < 25"
                        class="block rounded-md bg-secondary px-3 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-secondary-dark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outlibg-secondary" >
                    Tag anlegen
                </button >
            </div >
        </div >
        <div class="mt-4 flow-root" >
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" >
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8" >
                    <table class="min-w-full divide-y divide-gray-300" >
                        <thead >
                        <tr >
                            <th scope="col"
                                class="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-900 sm:pl-0" >Name
                            </th >
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-medium text-gray-900" >Farbe</th >
                            <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0" >
                                <span class="sr-only" >Bearbeiten</span >
                            </th >
                        </tr >
                        </thead >
                        <tbody class="divide-y divide-gray-200" >
                        <tr v-for="(tag, index) in tags" :key="index" >
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-0" >
                                <div v-if="editTagIndex !== index && tags[index].id" :style="`color: ${tag.color};`" >
                                    {{ tag.name }}
                                </div >
                                <div v-if="editTagIndex === index || !tags[index].id" >
                                    <input type="text" v-model="tag.name" @keydown="checkNameLength"
                                           class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ribg-secondary sm:text-sm sm:leading-6"
                                           :placeholder="tag.name || 'Tagnamen eingeben'" />
                                    <small class="text-gray-400" >{{ tag.name.length }}/20</small >
                                </div >
                            </td >
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex flex-col" >
                                <div v-if="editTagIndex !== index && tags[index].id" class="h-3 w-3 rounded-full"
                                     :style="`background-color: ${tag.color};`" ></div >
                                <div v-if="editTagIndex === index || !tags[index].id" >
                                    <input type="color" v-model="tag.color"
                                           class="h-10 w-10 block rounded-md border-0 py-1.5å text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ribg-secondary sm:text-sm sm:leading-6" />
                                </div >
                            </td >
                            <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0" >
                                <button v-if="editTagIndex !== index && tags[index].id" type="button"
                                        @click="editSelectedTag(index)"
                                        class="text-gray-600 hover:text-indigo-900 cursor-pointer mr-3" >
                                    Bearbeiten
                                </button >

                                <button v-if="editTagIndex !== index && tags[index].id" type="button"
                                        @click="deleteSelectedTag(index)"
                                        class="text-gray-600 hover:text-indigo-900 cursor-pointer mr-3" >
                                    Löschen
                                </button >

                                <button v-if="editTagIndex === index" type="button" @click="updateSelectedTag(index)"
                                        class="text-gray-600 hover:text-indigo-900 cursor-pointer mr-3" >
                                    Speichern
                                </button >

                                <button v-if="editTagIndex === index" type="button" @click="cancelEditingTag(index)"
                                        class="text-gray-600 hover:text-indigo-900 cursor-pointer mr-3" >
                                    Abbrechen
                                </button >

                                <button v-if="!tags[index].id" type="button" @click="storeSelectedTag(index)"
                                        class="text-gray-600 hover:text-indigo-900 cursor-pointer mr-3" >
                                    Anlegen
                                </button >

                                <button v-if="!tags[index].id" type="button" @click="removeEmptyTag(index)"
                                        class="text-gray-600 hover:text-indigo-900 cursor-pointer mr-3" >
                                    Abbrechen
                                </button >
                            </td >
                        </tr >
                        </tbody >
                    </table >
                </div >
            </div >
        </div >
    </div >
</template >

<script >
import Cookies from "js-cookie";

export default {
    data() {
        return {
            editTagIndex: false,
            tags: [],
        };
    },

    methods: {
        fetchTags() {
            Cookies.set('tags', JSON.stringify([]), {expires: 1, path: ''});
            axios.get('/api/tags')
                .then(response => {
                    this.tags = response.data;
                    Cookies.set('tags', JSON.stringify(this.tags), {expires: 1, path: ''});
                });

            // TODO: Remove this after implementing the API
            // this.tags = [
            //     { id: 1, name: 'Klärfall', color: '#007dff'},
            //     { id: 2, name: 'Geklärt', color: '#007d40'},
            // ];
        },

        createTag() {
            this.tags = [{
                id: null,
                name: '',
                color: '#000000',
            }].concat(this.tags);
        },

        editSelectedTag(tagIndex) {
            this.editTagIndex = tagIndex;
        },

        storeSelectedTag(tagIndex) {
            axios
                .post('/api/tags/', {
                    name: this.tags[tagIndex].name,
                    color: this.tags[tagIndex].color,
                })
                .then(response => {
                    this.fetchTags();
                    this.editTagIndex = false;
                    this.$swal({
                        text: `Tag angelegt`,
                        icon: 'success',
                        showConfirmButton: true,
                        confirmButtonText: 'OK',
                    });
                });
        },

        updateSelectedTag(tagIndex) {
            axios
                .patch('/api/tags/' + this.tags[tagIndex].id, {
                    name: this.tags[tagIndex].name,
                    color: this.tags[tagIndex].color,
                })
                .then(response => {
                    this.fetchTags();
                    this.editTagIndex = false;
                    this.$swal({
                        text: `Tag aktualisiert`,
                        icon: 'success',
                        showConfirmButton: true,
                        confirmButtonText: 'OK',
                    });
                });
        },

        async deleteSelectedTag(tagIndex) {
            let confirmation = await this.$swal({
                text: `Tag wirklich löschen?`,
                icon: 'warning',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Löschen',
                cancelButtonText: 'Abbrechen',
            })

            if (confirmation.isConfirmed) {
                axios
                    .delete('/api/tags/' + this.tags[tagIndex].id)
                    .then(response => {
                        this.fetchTags();
                        this.$swal({
                            text: `Tag gelöscht`,
                            icon: 'success',
                            showConfirmButton: true,
                            confirmButtonText: 'OK',
                        });
                    });
            }
        },

        removeEmptyTag(tagIndex) {
            this.tags.splice(tagIndex, 1);
        },

        cancelEditingTag(tagIndex) {
            this.editTagIndex = false;
        },

        checkNameLength(event) {
            if (event.target.value.length > 19) {
                if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
                    return;
                }
                event.preventDefault();
            }
        },
    },

    mounted() {
        this.fetchTags();
    }
}
</script >
