<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="relative z-10" @close="close">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="hidden sm:fixed sm:inset-0 sm:block sm:bg-gray-500 sm:bg-opacity-75 sm:transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-stretch justify-center text-center sm:items-center sm:px-6 lg:px-8">
                    <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-105" enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-105">
                        <DialogPanel class="flex w-full max-w-3xl transform text-left text-base transition sm:my-8">
                            <div class="relative flex w-full flex-col overflow-hidden bg-white pt-6 pb-8 sm:rounded-lg sm:pb-6 lg:py-8">
                                <div>
                                    <div class="flex items-center justify-end px-4 sm:px-6 lg:px-8">
                                        <button type="button" class="text-gray-400 hover:text-gray-500" @click="close">
                                            <span class="sr-only">Close</span>
                                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>

                                    <div class="px-4 sm:px-6 lg:px-8 -mt-5">
                                        <section>
                                            <slot name="body"></slot>
                                        </section>
                                    </div>
                                </div>

                                <slot name="footer">

                                </slot>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
export default {
    components: {
        Dialog, DialogPanel, DialogTitle, TransitionRoot, TransitionChild, XMarkIcon,
    },

    data() {
        return {
        };
    },

    methods: {
        close() {
            this.$emit('modal-close');
        }
    },

    mounted() {
    },

    props: [
            "show",
    ]
}
</script>
