<template >
    <div v-if="loadingSpinner"
         class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-slate-600 flex flex-col items-center justify-center opacity-70" >
        <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4" ></div >
        <h2 class="text-center text-white text-xl font-semibold" >Wird geladen...</h2 >
        <p class="w-1/3 text-center text-white" >Dies kann wenige Sekunden dauern. Bitte die Seite nicht schließen.</p >
    </div >

    <div >
        <div >
            <div class="text-xl text-gray-600 mt-4 mb-4" >Kennzahlen</div >

            <div class="flex flex-wrap space-x-0 lg:space-x-5 space-y-5 lg:space-y-0 items-center" >
                <div
                    class="rounded bg-slate-100 p-4 flex flex-row w-full lg:flex-1 items-center transition-all" >
                    <div class="flex flex-col flex-1" >
                        <div class="flex flex-row items-center" >
                            <div class="p-2 bg-error-over-ordering rounded mr-6" >
                                <ExclamationCircleIcon class="text-white h-7" />
                            </div >

                            <div class="flex flex-col justify-center" >
                                <div class="text-gray-600 text-lg leading-6" >
                                    Offene Aufgaben
                                </div >
                                <div class="font-semibold text-xl leading-tight" >
                                    {{ notifications.pending_tasks }}
                                </div >
                            </div >
                        </div >
                    </div >

                </div >

                <div
                    class="rounded bg-slate-100 p-4 flex flex-row w-full lg:flex-1 items-center transition-all" >
                    <div class="flex flex-col flex-1" >
                        <div class="flex flex-row items-center" >
                            <div class="p-2 bg-yellow-400 rounded mr-6" >
                                <ExclamationCircleIcon class="text-white h-7" />
                            </div >

                            <div class="flex flex-col justify-center" >
                                <div class="text-gray-600 text-lg leading-6" >
                                    Erledigt heute
                                </div >
                                <div class="font-semibold text-xl leading-tight" >
                                    {{ notifications.completed_today }}
                                </div >
                            </div >
                        </div >
                    </div >
                </div >

                <div
                    class="rounded bg-slate-100 p-4 flex flex-row w-full lg:flex-1 items-center transition-all" >
                    <div class="flex flex-col flex-1" >
                        <div class="flex flex-row items-center" >
                            <div class="p-2 bg-violet-400 rounded mr-6" >
                                <ExclamationCircleIcon class="text-white h-7" />
                            </div >

                            <div class="flex flex-col justify-center" >
                                <div class="text-gray-600 text-lg leading-6" >
                                    Erledigt letzte 7 Tage
                                </div >
                                <div class="font-semibold text-xl leading-tight" >
                                    {{ notifications.completed_7_days }}
                                </div >
                            </div >
                        </div >
                    </div >
                </div >
            </div >
        </div >

        <div class="mt-8" >
            <div class="" >
                <div class="text-xl text-gray-600 mb-4" >Aufgaben</div >

                <div class="flex flex-wrap space-x-0 lg:space-x-5 space-y-5 lg:space-y-0 items-center" >
                    <Tasks />
                </div >
            </div >
        </div >
    </div >
</template >

<script >
import {
    ArrowRightIcon,
    ChatBubbleBottomCenterTextIcon,
    CheckCircleIcon,
    ExclamationCircleIcon,
    PlusCircleIcon
} from "@heroicons/vue/24/outline";
import Modal from "../components/Modal.vue";
import OrderCreate from "../components/OrderCreate.vue";
import GoodsCreate from "../components/GoodsCreate.vue";
import Tasks from "../components/Tasks.vue";

export default {
    components: {
        GoodsCreate,
        OrderCreate, Modal,
        ExclamationCircleIcon,
        ArrowRightIcon,
        CheckCircleIcon,
        PlusCircleIcon,
        ChatBubbleBottomCenterTextIcon,
        Tasks,
    },

    data() {
        return {
            loadingSpinner: false,
            notifications: {
                pending_tasks: '-',
                completed_today: '-',
                completed_7_days: '-',
            },
        };
    },

    methods: {
        fetchData() {
            this.loadingSpinner = true;
            axios.get('/api/team-dashboard')
                .then(response => {
                    this.notifications = response.data.data.notifications;

                    this.submitSuccess = true;
                    this.$emit('close-me');
                })
                .catch(error => {
                    this.$swal({
                        title: 'Fehler beim Laden der Daten',
                        text: error.message,
                        icon: 'error',
                        showConfirmButton: true,
                        confirmButtonText: 'OK',
                    });
                })
                .finally(() => {
                    this.loadingSpinner = false;
                });
        },
    },

    mounted() {
        this.fetchData();
    }
}
</script >
