<template >
    <div >
        <div class="text-xl font-medium mb-5" >
            {{ task.subject }}
        </div >

        <div class="space-y-2" >
            <div class="flex flex-col space-y-3 !mt-5" >
                <div class="text-sm font-medium text-gray-500" >
                    Kommentar
                </div >
                <div class="text-sm" >
                    <textarea v-model="task.team_comment" rows="2"
                              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div >
            </div >

            <div class="flex justify-end" >
                <button type="button"
                        @click="comment"
                        class="flex justify-center items-center bg-secondary hover:bg-secondary-dark rounded-md px-3 py-2 text-white font-light h-10" >
                    Kommentieren
                </button >
            </div >
        </div >
    </div >
</template >

<script >
import moment from "moment";

export default {
    props: {
        task: {
            type: Object,
            required: true
        }
    },
    computed: {
        isCustomer() {
            return window.isCustomer();
        },

        moment() {
            return moment;
        },
    },

    methods: {
        statusBadgeClass(status) {
            return {
                'bg-slate-200 text-slate-800': status === 'created',
                'bg-green-200 text-green-800': status === 'completed',
                'bg-red-200 text-red-800': status === 'cancelled',
            }
        },

        translatedStatus(status) {
            return {
                'created': 'Erstellt',
                'completed': 'Erfolgreich',
                'cancelled': 'Abgebrochen',
            }[status];
        },

        comment() {
            axios
                .put(`/api/tasks/${this.task.id}/comment`, {team_comment: this.task.team_comment})
                .then(() => this.$emit('commented'));
        }
    },
}
</script >
