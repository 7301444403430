<template>
    <form>
        <div class="text-gray-900 flex mb-3">
            <div class="text-lg font-medium ">
                Neue Lieferung anmelden
            </div>
        </div>

<!--        <div class="mt-8">-->
<!--            <steps :steps="3" :step="step"></steps>-->
<!--        </div>-->

        <div class="flex flex-col justify-between h-full">
            <div v-if="step == 1" class="flex-1">
                <ul v-if="validationErrors.hints.length" class="list-disc list-inside grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-x-4 text-primary-dark">
                    <li v-for="hint in validationErrors.hints">{{hint}}</li>
                </ul>
                <hr class="mt-3 mb-5">

                <div class="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-4">
                    <div>
                        <label for="internal_good_number" class="block text-sm font-medium text-gray-700">Eigene Liefernummer</label>
                        <div class="mt-1">
                            <input v-model="good.internal_good_number" type="text" id="internal_good_number" name="internal_good_number" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-indigo-500 sm:text-sm" :class="validationErrors.fields.internal_good_number ? ['!border-primary'] : []" />
                        </div>
                    </div>
                    <div>
                        <label for="supplier_name" class="block text-sm font-medium text-gray-700">Name Lieferant</label>
                        <div class="mt-1">
                            <input v-model="good.supplier_name" type="text" id="supplier_name" name="supplier_name"
                                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                   :class="validationErrors.fields.supplier_name ? ['!border-primary'] : []"
                            />
                        </div>
                    </div>
                    <div>
                        <label for="delivery_date" class="block text-sm font-medium text-gray-700">Lieferdatum</label>
                        <div class="mt-1">
                            <vue-tailwind-datepicker
                                v-model="good.delivery_date"
                                as-single
                                use-range
                                :input-classes="['block w-full rounded-md border-gray-300 px-4 shadow-sm focus:border-primary focus:ring-primary sm:text-sm', validationErrors.fields.delivery_date ? '!border-primary' : '']"
                                placeholder="Datum auswählen"
                                separator=" - "
                                :formatter="{date: 'DD.MM.YY', month: 'MMM'}"
                                :disable-date="(date) => date < new Date"
                                :shortcuts="false"
                                :options="{
                                   footer: {
                                        apply: 'Auswählen',
                                        cancel: 'Abbrechen'
                                  }
                                }"
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-6 grid grid-cols-1 gap-y-4">
                    <div>
                        <label for="supplier_name" class="block text-sm font-medium text-gray-700" :class="validationErrors.fields.content_packaging ? ['!text-primary'] : []">Inhalt Packstücke</label>
                        <div class="flex space-between">
                            <div class="flex-1 flex items-center">
                                <input v-model="good.content_packaging" id="Sortenrein" type="checkbox" value="Sortenrein" class="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:ring-2">
                                <label for="Sortenrein" class="w-full py-3 ml-2 text-sm font-medium text-gray-700">Sortenrein</label>
                            </div>
                            <div class="flex-1 flex items-center">
                                <input v-model="good.content_packaging" id="Gemischt" type="checkbox" value="Gemischt" class="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:ring-2">
                                <label for="Gemischt" class="w-full py-3 ml-2 text-sm font-medium text-gray-700">Gemischt</label>
                            </div>
                            <div class="flex-1 flex items-center">
                                <input v-model="good.content_packaging" id="Keine Angabe" type="checkbox" value="Keine Angabe" class="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:ring-2">
                                <label for="Keine Angabe" class="w-full py-3 ml-2 text-sm font-medium text-gray-700">Keine Angabe</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-6 grid grid-cols-1 gap-y-4">
                    <div>
                        <label for="supplier_name" class="block text-sm font-medium text-gray-700" :class="validationErrors.fields.type_packaging ? ['!text-primary'] : []">Art der Packstücke</label>
                        <div class="flex space-between">
                            <div class="flex-1 flex items-center">
                                <input v-model="good.type_packaging" id="Karton" type="checkbox" value="Karton" class="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:ring-2">
                                <label for="Karton" class="w-full py-3 ml-2 text-sm font-medium text-gray-700">Karton</label>
                            </div>
                            <div class="flex-1 flex items-center">
                                <input v-model="good.type_packaging" id="Euro-Palette" type="checkbox" value="Euro-Palette" class="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:ring-2">
                                <label for="Euro-Palette" class="w-full py-3 ml-2 text-sm font-medium text-gray-700">Euro-Palette</label>
                            </div>
                            <div class="flex-1 flex items-center">
                                <input v-model="good.type_packaging" id="Lose" type="checkbox" value="Lose" class="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:ring-2">
                                <label for="Lose" class="w-full py-3 ml-2 text-sm font-medium text-gray-700">Lose</label>
                            </div>
                            <div class="flex-1 flex items-center">
                                <input v-model="good.type_packaging" id="type_packaging-Keine Angabe" type="checkbox" value="Keine Angabe" class="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:ring-2">
                                <label for="type_packaging-Keine Angabe" class="w-full py-3 ml-2 text-sm font-medium text-gray-700">Keine Angabe</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-6 grid grid-cols-2 gap-y-4 sm:gap-x-4">
                    <div class="flex flex-col">
                        <label for="Wie erfolgt die Anlieferung?" class="block text-sm font-medium text-gray-700">Wie erfolgt die Anlieferung?</label>
                        <div class="mt-1">
                            <select v-model="good.form_of_delivery" id="form_of_delivery"
                                    name="form_of_delivery"
                                    class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" :class="validationErrors.fields.form_of_delivery ? ['!border-primary'] : []">
                                <option disabled value="">Auswählen</option>
                                <option value="Paketdienst">Paketdienst</option>
                                <option value="Spedition">Spedition</option>
                                <option value="Container">Container</option>
                                <option value="Selbstanlieferung">Selbstanlieferung</option>
                                <option value="Abholung Beckmann">Abholung Beckmann</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <label for="shipping_service_provider" class="block text-sm font-medium text-gray-700">Versanddienstleister</label>
                        <div class="mt-1">
                            <input v-model="good.shipping_service_provider" type="text" id="shipping_service_provider" name="shipping_service_provider" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-indigo-500 sm:text-sm" :class="validationErrors.fields.shipping_service_provider ? ['!border-primary'] : []" />
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <label for="Produkte mit Barcode" class="block text-sm font-medium text-gray-700">Alle Produkte mit Barcode ausgezeichnet?</label>
                        <div class="mt-1">
                            <select v-model="good.products_with_barcode" id="products_with_barcode"
                                    name="products_with_barcode"
                                    class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" :class="validationErrors.fields.products_with_barcode ? ['!text-primary'] : []">
                                <option value="Ja">Ja</option>
                                <option value="Nein">Nein</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <label for="number_of_packaging" class="block text-sm font-medium text-gray-700">Anzahl Packstücke (in Stück)</label>
                        <div class="mt-1">
                            <input v-model="good.number_of_packaging" type="number" min="1" id="number_of_packaging" name="number_of_packaging" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-indigo-500 sm:text-sm" :class="validationErrors.fields.number_of_packaging ? ['!border-primary'] : []" />
                        </div>
                    </div>

                    <div>
                        <label for="number_of_different_positions" class="block text-sm font-medium text-gray-700">Anzahl der verschiedenen Positionen (SKU’s)</label>
                        <div class="mt-1">
                            <input v-model="good.number_of_different_positions" type="number" min="1" id="number_of_different_positions" name="number_of_different_positions" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-indigo-500 sm:text-sm" :class="validationErrors.fields.number_of_different_positions ? ['!border-primary'] : []" />
                        </div>
                    </div>
                </div>
                <div class="mt-6 grid grid-cols-1 gap-y-4">
                    <div>
                        <label for="comment" class="block text-sm font-medium text-gray-700">Kommentar</label>
                        <div class="mt-1">
                            <textarea v-model="good.comment"  id="comment" name="comment" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-indigo-500 sm:text-sm" :class="validationErrors.fields.comment ? ['!border-primary'] : []" />
                        </div>
                    </div>
                </div>
                <div class="mt-3 text-gray-700"><strong>Hinweis:</strong> Bitte Lieferschein und Informationen als PDF oder Bild anhängen, kein .xlsx.</div>
            </div>

            <div class="mt-3 flex justify-end">
                <a href="https://beckmann.notion.site/Vorgaben-Lieferung-7a964922117c4b51891a8b7bc505bbca?pvs=4" target="_blank" class="rounded-md border border-primary bg-white py-2 px-4 text-sm text-primary shadow-sm focus:outline-none focus:ring-2 focus:bg-white focus:ring-offset-2 focus:ring-offset-gray-50 mr-5">
                    Vorgaben Lieferung
                </a>
                <button @click="generateMail" type="button" class="rounded-md border border-transparent bg-primary py-2 px-4 text-sm text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:bg-primary-dark focus:ring-offset-2 focus:ring-offset-gray-50">
                    Anmelden
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import {PlusIcon, TrashIcon, ExclamationTriangleIcon, CheckIcon, InformationCircleIcon} from '@heroicons/vue/24/outline'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import Steps from './Steps.vue'
import Multiselect from '@vueform/multiselect'
import VueTailwindDatepicker from 'vue-tailwind-datepicker'
import {ChevronDownIcon} from "@heroicons/vue/20/solid";

export default {
    components: {
        ChevronDownIcon,
        PlusIcon,
        TrashIcon,
        CheckIcon,
        ExclamationTriangleIcon,
        InformationCircleIcon,
        Steps,
        Multiselect,
        VueTailwindDatepicker,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
    },

    data() {
        return {
            good: {
                supplier_name: '',
                internal_good_number: '',
                delivery_date: {
                    startDate: '',
                    endDate: ''
                },
                content_packaging: [],
                type_packaging: [],
                form_of_delivery: '',
                shipping_service_provider: '',
                products_with_barcode: "Ja",
                number_of_packaging: 1,
                number_of_different_positions: '1',
                comment: '',
            },
            step: 1,
            validationErrors: {
                fields: {},
                hints: [],
                message: null,
            },
        };
    },

    methods: {
        close() {
            this.$emit('close-me');
        },

        resetValidation() {
            this.validationErrors = {
                fields: {
                    positions: []
                },
                hints: [],
                message: null,
                validated: false,
                valid: true,
            }
        },

        validateStep() {
            this.resetValidation();
            switch (this.step) {
                case 1:
                    if (!this.good.internal_good_number) {
                        this.validationErrors.fields.internal_good_number = true;
                        this.validationErrors.hints.push('Eigene Liefernummer eingeben');
                        this.validationErrors.valid = false;
                    }
                    if (!this.good.supplier_name) {
                        this.validationErrors.fields.supplier_name = true;
                        this.validationErrors.hints.push('Name des Lieferanten eingeben');
                        this.validationErrors.valid = false;
                    }
                    if (!this.good.delivery_date.startDate || !this.good.delivery_date.endDate) {
                        this.validationErrors.fields.delivery_date = true;
                        this.validationErrors.hints.push('Lieferdatum auswählen');
                        this.validationErrors.valid = false;
                    }
                    if (this.good.content_packaging.length === 0) {
                        this.validationErrors.fields.content_packaging = true;
                        this.validationErrors.hints.push('Inhalt Packstücke auswählen');
                        this.validationErrors.valid = false;
                    }
                    if (this.good.type_packaging.length === 0) {
                        this.validationErrors.fields.type_packaging = true;
                        this.validationErrors.hints.push('Art der Packstücke auswählen');
                        this.validationErrors.valid = false;
                    }
                    if (!this.good.form_of_delivery) {
                        this.validationErrors.fields.form_of_delivery = true;
                        this.validationErrors.hints.push('Form der Lieferung auswählen');
                        this.validationErrors.valid = false;
                    }
                    if (!this.good.number_of_packaging) {
                        this.validationErrors.fields.number_of_packaging = true;
                        this.validationErrors.hints.push('Anzahl Packstücke fehlt');
                        this.validationErrors.valid = false;
                    }

                    if (this.good.number_of_packaging < 1) {
                        this.validationErrors.fields.number_of_packaging = true;
                        this.validationErrors.hints.push('Anzahl Packstücke darf nicht weniger als 1 sein');
                        this.validationErrors.valid = false;
                    }

                    if (!this.good.number_of_different_positions) {
                        this.validationErrors.fields.number_of_different_positions = true;
                        this.validationErrors.hints.push('Anzahl der verschiedenen Positionen (SKU’s) fehlt');
                        this.validationErrors.valid = false;
                    }

                    if (this.good.number_of_different_positions < 1) {
                        this.validationErrors.fields.number_of_different_positions = true;
                        this.validationErrors.hints.push('Anzahl der verschiedenen Positionen (SKU’s) darf nicht weniger als 1 sein');
                        this.validationErrors.valid = false;
                    }
                    break;
            }
            this.validationErrors.validated = true;
            return this.validationErrors.valid;
        },

        generateMail() {
            if (this.validateStep()) {
                window.open(`mailto:support@beckmannsys.com?subject=Anmeldung Lieferung: ${this.good.delivery_date.startDate} - ${this.good.delivery_date.endDate}&body=Eigene Liefernummer: ${this.good.internal_good_number}%0D%0AName Lieferant: ${this.good.supplier_name}%0D%0ALieferdatum: ${this.good.delivery_date.startDate} - ${this.good.delivery_date.endDate}%0D%0A%0D%0AInhalt Packstücke: ${this.good.content_packaging}%0D%0AArt der Packstücke: ${this.good.type_packaging}%0D%0AWie erfolgt die Anlieferung?: ${this.good.form_of_delivery}%0D%0AVersanddienstleister: ${this.good.shipping_service_provider}%0D%0AAlle Produkte mit Barcode ausgezeichnet: ${this.good.products_with_barcode}%0D%0AAnzahl Packstücke: ${this.good.number_of_packaging }%0D%0AAnzahl der verschiedenen Positionen (SKU’s): ${this.good.number_of_different_positions }%0D%0A%0D%0AKommentar: ${this.good.comment}`, '_blank');

                this.close();
            }
        },
    },
}
</script>
