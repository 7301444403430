<template >
    <div >
        <Menu as="div" class="relative inline-block text-left" >
            <div >
                <MenuButton
                    class="h-10 inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-secondary-dark border border-secondary-dark shadow-sm hover:bg-gray-50" >
                    <ListBulletIcon class="h-5 inline-block mr-1" />
                    Aufgabe erstellen
                    <ChevronDownIcon class="h-5 inline-block ml-1" ></ChevronDownIcon >
                </MenuButton >
            </div >

            <transition enter-active-class="transition ease-out duration-100"
                        enter-from-class="transform opacity-0 scale-95"
                        enter-to-class="transform opacity-100 scale-100"
                        leave-active-class="transition ease-in duration-75"
                        leave-from-class="transform opacity-100 scale-100"
                        leave-to-class="transform opacity-0 scale-95" >
                <MenuItems
                    :class="['absolute z-10 mt-2 w-56 cursor-pointer origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none', {'right-0': alignRight}, {'left-0': alignLeft}]" >
                    <div class="py-1" >
                        <MenuItem v-slot="{ active }"
                                  class="hover:bg-gray-100"
                                  v-for="task in tasks"
                                  :key="task.label"
                                  @click="openModal(task)" >
                            <a target="_blank" download
                               class="block px-4 py-2 text-sm" >{{ task.label }}</a >
                        </MenuItem >
                    </div >
                </MenuItems >
            </transition >
        </Menu >
    </div >

    <Modal :show="this.showModal" @modalClose="closeModal" >
        <template v-slot:body >
            <div >
                <div class="text-xl font-medium mb-5" >
                    {{ selectedTask.subject }}
                </div >

                <div class="space-y-2" >
                    <div class="flex flex-col space-y-3 !mt-5" >
                        <div class="text-sm font-medium text-gray-500" >
                            Informationen (optional)
                        </div >
                        <div class="text-sm" >
                    <textarea v-model="clientInformation" rows="2"
                              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder="Füge hier Details oder Hinweise zu deiner Aufgabe hinzu." />
                        </div >
                    </div >

                    <div class="flex justify-end" >
                        <button type="button"
                                @click="submitTask"
                                class="flex justify-center items-center bg-secondary hover:bg-secondary-dark rounded-md px-3 py-2 text-white font-light h-10" >
                            Aufgabe anlegen
                        </button >
                    </div >
                </div >
            </div >
        </template >
    </Modal >
</template >

<script >
import {InboxArrowDownIcon, ListBulletIcon} from "@heroicons/vue/24/outline";
import {ChevronDownIcon} from "@heroicons/vue/20/solid";
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue';
import Modal from "./Modal.vue";

export default {
    components: {Modal, ChevronDownIcon, InboxArrowDownIcon, Menu, MenuButton, MenuItem, MenuItems, ListBulletIcon},

    props: {
        tasks: {
            type: Array,
            required: true,
        },
        alignRight: {
            type: Boolean,
        },
        alignLeft: {
            type: Boolean,
        },
    },

    data() {
        return {
            showModal: false,
            selectedTask: null,
            clientInformation: null,
        };
    },

    methods: {
        openModal(task) {
            this.showModal = true;
            this.selectedTask = task;
        },

        closeModal() {
            this.showModal = false;
        },

        submitTask() {
            axios
                .post('/api/tasks', {
                    subject: this.selectedTask.subject,
                    client_information: this.clientInformation
                })
                .then(response => {
                    this.$swal({
                        text: `Aufgabe erfolgreich erstellt!`,
                        icon: 'success',
                        showConfirmButton: true,
                    });

                    this.closeModal();
                });
        }
    }
}
</script >
