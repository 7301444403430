<template>
    <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="mt-5 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <div>
                <img class="mx-auto h-10 w-auto" src="/images/logo.webp" alt="Your Company">
                    <h2 class="mt-5 text-center text-3xl font-bold tracking-tight text-gray-900">Herzlich willkommen!</h2>
                    <p class="text-center mt-3 mb-3 text-gray-500">
                        Melde dich bitte mit deinem Benutzernamen <br/>oder deiner E-Mail und deinem Passwort an.
                    </p>
                    <p v-if="loginFailed" class="text-center text-sm mt-3 mb-3 text-red-500">
                        Ungültiger Benutzername, ungültige E-Mail oder ungültiges Kennwort
                    </p>
                </div>

                <form @submit.prevent="submitForm" class="space-y-6" method="POST">
                    <div>
                        <label for="username" class="block text-sm font-medium text-gray-700" :class="loginFailed ? '!text-red-500' : ''">Benutzername oder E-Mail</label>
                        <div class="mt-1">
                            <input v-model="form.username" id="username" name="text" type="text" autocomplete="email" required class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none focus:ring-primary sm:text-sm" :class="loginFailed ? '!border-red-500' : ''">
                        </div>
                    </div>

                    <div>
                        <label for="password" class="block text-sm font-medium text-gray-700" :class="loginFailed ? '!text-red-500' : ''">Passwort</label>
                        <div class="mt-1">
                            <input v-model="form.password" id="password" name="password" type="password" autocomplete="current-password" required class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none focus:ring-primary sm:text-sm" :class="loginFailed ? '!border-red-500' : ''">
                        </div>
                    </div>

                    <div>
                        <button type="submit" class="flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
                            <span v-if="loading">Bitte warten...</span>
                            <span v-else>Anmelden</span>
                        </button>
                    </div>

                    <div class="flex items-center justify-center">
                        <div class="text-sm">
                            <router-link to="/forgot-password" class="font-lighter text-gray-500 hover:text-gray-700">Passwort vergessen?</router-link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Cookies from 'js-cookie';

export default {
    data() {
        return {
            loading: false,
            loginFailed: false,
            form: {
                username: '',
                password: '',
            },
        };
    },

    methods: {
        submitForm() {
            this.loading = true;

            axios.post('/api/login', this.form)
                .then((response) => {
                    if (!response.data.success) {
                        this.loginFailed = true;
                        this.loading = false;
                        return;
                    }

                    this.loginFailed = false;
                    this.loading = false;

                    let token = response.data.token;
                    let level = response.data.level;

                    Cookies.set('token', token, { expires: 1, path: '' });
                    Cookies.set('level', level, { expires: 1, path: '' });

                    location.href = '/';
                })
                .catch(error => {
                    this.loading = false;
                    this.loginFailed = true;
                });
        }
    }
}
</script>
