export default {
    methods: {
        async completeTask(closeModal = false) {
            let confirmation = await this.$swal({
                text: `Möchten Sie die ausgewählte Aufgabe abschließen?`,
                icon: 'warning',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Abschließen',
                cancelButtonText: 'Abbrechen',
            })

            if (confirmation.isConfirmed) {
                axios
                    .patch(`/api/tasks/${this.task.id}/complete`)
                    .then(() => {
                        this.$emit('updated');
                        if (closeModal) {
                            this.showDetailModal = false;
                        }
                    });
            }
        },

        async resetTask(closeModal = false) {
            let confirmation = await this.$swal({
                text: `Möchten Sie die ausgewählte Aufgabe zurücksetzen?`,
                icon: 'warning',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Zurücksetzen',
                cancelButtonText: 'Abbrechen',
            })

            if (confirmation.isConfirmed) {
                axios
                    .patch(`/api/tasks/${this.task.id}/reset`)
                    .then(() => {
                        this.$emit('updated');
                        if (closeModal) {
                            this.showDetailModal = false;
                        }
                    });
            }
        },

        async cancelTask(closeModal = false) {
            let confirmation = await this.$swal({
                text: `Möchten Sie die ausgewählte Aufgabe stornieren?`,
                icon: 'warning',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Stornieren',
                cancelButtonText: 'Abbrechen',
            })

            if (confirmation.isConfirmed) {
                axios
                    .patch(`/api/tasks/${this.task.id}/cancel`)
                    .then(() => {
                        this.$emit('updated');
                        if (closeModal) {
                            this.showDetailModal = false;
                        }
                    });
            }
        },
    }
}
