<template>
    <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="mt-5 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <div>
                    <router-link to="/login"><img class="mx-auto h-10 w-auto" src="/images/logo.webp" alt="Your Company">
                        <h2 class="mt-5 text-center text-3xl font-bold tracking-tight text-gray-900">Passwort zurücksetzen</h2>
                        <p class="text-center mt-3 mb-3 text-gray-500">
                            Gib deinen Benutzernamen oder deine E-Mail ein, um dein Passwort zurückzusetzen.
                        </p>
                    </router-link>
                </div>

                <form @submit.prevent="submitForm" class="space-y-6" method="POST">
                    <div>
                        <label for="username" class="block text-sm font-medium text-gray-700">Benutzername oder E-Mail</label>
                        <div class="mt-1">
                            <input v-model="form.username" id="username" name="text" type="text" autocomplete="email" required class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none focus:ring-primary sm:text-sm">
                        </div>
                    </div>

                    <div>
                        <button type="submit" class="flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
                            <span v-if="loading">Bitte warten...</span>
                            <span v-else>Passwort zurücksetzen</span>
                        </button>
                    </div>

                    <div class="flex items-center justify-center">
                        <div class="text-sm">
                            <router-link to="/login" class="font-lighter text-gray-500 hover:text-gray-700 flex items-center">
                                <ArrowLeftIcon class="h-4 mr-1 inline-block" /> Zurück zum Login</router-link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { ArrowLeftIcon } from '@heroicons/vue/24/outline';

export default {
    components: {
        ArrowLeftIcon,
    },

    data() {
        return {
            loading: false,
            form: {
                username: '',
            },
        };
    },

    methods: {
        submitForm() {
            this.loading = true;
            axios.post('/api/forgot-password', this.form)
                .then((response) => {
                    this.$router.push('/login');
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
}
</script>
