<template >
    <div >
        <div class="text-gray-900 flex mb-2" >
            <div class="text-lg font-medium " >
                Artikel: {{ product.id }}
            </div >

            <div v-if="product.verfuegbar < 0" class="ml-16 flex items-center" >
                <span
                    class="inline-flex items-center rounded-full bg-error-over-ordering px-3 py-0.5 text-sm text-black" >
                    <ExclamationTriangleIcon class="h-5 mr-2" /> Überbestellung
                </span >
            </div >
        </div >

        <div class="flex justify-between items-center mb-7" >
            <div class="flex text-gray-600 text-sm space-x-10" >
                <div >Importdatum:
                    <FormatDate :date="product.imported_at" />
                </div >
                <div >Name: {{ product.name }}</div >
            </div >

            <div class="flex" >
                <div class="mr-4" >
                    <!--                    <TaskModal align-right :tasks="[-->
                    <!--                        {-->
                    <!--                            label: 'Bestand prüfen',-->
                    <!--                            subject: `Artikel ${productId} - Bestand prüfen`,-->
                    <!--                        },-->
                    <!--                        {-->
                    <!--                            label: 'Artikel sperren',-->
                    <!--                            subject: `Artikel ${productId} - Artikel sperren`,-->
                    <!--                        },-->
                    <!--                        {-->
                    <!--                            label: 'Artikel löschen',-->
                    <!--                            subject: `Artikel ${productId} - Artikel löschen`,-->
                    <!--                        },-->
                    <!--                    ]" />-->
                </div >

                <Menu as="div" class="relative inline-block text-left" >
                    <div >
                        <MenuButton
                            class="h-10 inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sky-700 border border-sky-700 shadow-sm hover:bg-gray-50" >
                            <TagIcon class="h-5 inline-block mr-1" />
                            {{ product.tag ?? 'Tag' }}
                            <ChevronDownIcon class="h-5 inline-block ml-1" ></ChevronDownIcon >
                        </MenuButton >
                    </div >

                    <transition enter-active-class="transition ease-out duration-100"
                                enter-from-class="transform opacity-0 scale-95"
                                enter-to-class="transform opacity-100 scale-100"
                                leave-active-class="transition ease-in duration-75"
                                leave-from-class="transform opacity-100 scale-100"
                                leave-to-class="transform opacity-0 scale-95" >
                        <MenuItems
                            class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" >
                            <div class="py-1" >
                                <MenuItem v-slot="{ active }" class="hover:bg-gray-100"
                                          :class="tag.name === product.tag ? 'bg-gray-100' : ''" v-for="tag in tags"
                                          :key="tag" >
                                    <div class="flex justify-between px-4 py-2 text-sm" >
                                        <div @click="setTag(tag)" class="flex-1 flex items-center" >
                                            <div class="h-2 w-2 rounded-full mr-2"
                                                 :style="`background-color: ${tag.color};`" ></div >
                                            {{ tag.name }}
                                        </div >
                                        <div >
                                            <XMarkIcon @click="unsetTag"
                                                       class="cursor-pointer text-gray-600 h-5 inline-block"
                                                       v-if="tag.name === product.tag" />
                                        </div >
                                    </div >
                                </MenuItem >
                            </div >
                        </MenuItems >
                    </transition >
                </Menu >
            </div >
        </div >

        <!--        <div class="flex justify-end">-->
        <!--            <div>-->
        <!--                <a :href="`/api/products/${productId}/csv/expanded`"-->
        <!--                   download-->
        <!--                   class="flex-1 h-10 inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-primary border border-primary shadow-sm ring-1 ring-inset ring-primary hover:bg-gray-50"-->
        <!--                >-->
        <!--                    <InboxArrowDownIcon class="h-5 inline-block mr-1"/>-->
        <!--                    Export .csv-->
        <!--                </a>-->
        <!--            </div>-->
        <!--        </div>-->

        <hr class="my-5" >

        <div class="sm:hidden mb-5" >
            <label for="tabs" class="sr-only" >Select a tab</label >
            <select id="tabs" name="tabs"
                    v-model="currentTab"
                    class="block w-full rounded-md border-gray-300 focus:border-secondary focus:ring-secondary" >
                <option v-for="tab in tabs" @change="currentTab = tab" >{{ tab }}
                </option >
            </select >
        </div >
        <div class="hidden sm:block mb-5" >
            <nav class="flex space-x-4" aria-label="Tabs" >
                <span v-for="tab in tabs"
                      :class="[tab == currentTab ? 'bg-secondary text-white' : 'text-gray-500 hover:text-gray-700', 'px-3 py-2 font-medium text-sm rounded-md cursor-pointer']"
                      :aria-current="tab == currentTab ? 'page' : undefined"
                      @click="currentTab = tab" >{{ tab }}</span >
            </nav >
        </div >

        <hr class="my-5" >

        <div v-show="currentTab == 'Übersicht'" >
            <table class="w-full rounded text-sm overflow-hidden" >
                <thead class="rounded-t" >
                <tr class="bg-gray-100 border border-solid border-gray-100 rounded-t" >
                    <th class="font-medium p-2 rounded-tl" ><label >Bestand gesamt</label ></th >
                    <th class="font-medium p-2" ><label >in Aufträgen</label ></th >
                    <th class="font-medium p-2" ><label >Bestand verfügbar</label ></th >
                    <th class="font-medium p-2 rounded-tr" ></th >
                </tr >
                </thead >
                <tbody class="rounded-b" >
                <tr class="border border-solid border-gray-100 rounded-b" >
                    <td class="p-2 rounded-bl" >{{ product.in_stock }}</td >
                    <td class="p-2" >{{ product.in_orders }}</td >
                    <td class="p-2" >{{ product.available }}</td >
                    <td class="p-2 rounded-br" >
                        <template v-if="product.available < 0" >
                        <span
                            class="inline-flex items-center rounded-full bg-error-over-ordering px-3 py-0.5 text-sm text-black" >
                            <ExclamationTriangleIcon class="h-5" />
                        </span >
                        </template >
                    </td >
                </tr >
                </tbody >
            </table >
            <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4" >
                <div >
                    <label for="gtin" class="block text-sm font-medium text-gray-700" >Barcode (GTIN)</label >
                    <div class="mt-1" >
                        <input v-model="product.gtin" disabled="disabled" type="text" id="gtin" name="gtin"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-indigo-500 sm:text-sm" />
                    </div >
                </div >
                <div >
                    <label for="last_receipt" class="block text-sm font-medium text-gray-700" >Letzter
                        Wareneingang</label >
                    <div class="mt-1" >
                        <input v-model="product.last_receipt" disabled="disabled" type="text" id="last_receipt"
                               name="last_receipt"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-indigo-500 sm:text-sm" />
                    </div >
                </div >
                <div >
                    <label for="sku" class="block text-sm font-medium text-gray-700" >SKU-Nummer</label >
                    <div class="mt-1" >
                        <input v-model="product.sku_number" disabled="disabled" type="text" id="sku" name="sku"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-indigo-500 sm:text-sm" />
                    </div >
                </div >
                <div >
                    <label for="last_ordered" class="block text-sm font-medium text-gray-700" >Letzte
                        Bestellung</label >
                    <div class="mt-1" >
                        <input v-model="product.last_ordered" disabled="disabled" type="text" id="last_ordered"
                               name="last_ordered"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-indigo-500 sm:text-sm" />
                    </div >
                </div >
                <div >
                    <label for="isbn" class="block text-sm font-medium text-gray-700" >Sonderfeld</label >
                    <div class="mt-1" >
                        <input v-model="product.isbn" disabled="disabled" type="text" id="isbn" name="isbn"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-indigo-500 sm:text-sm" />
                    </div >
                </div >
            </div >
        </div >
        <div v-show="currentTab == 'MHD'" >
            <v-client-table ref="mhds" :data="this.product.mhd" :columns="mhdColumns"
                            :options="mhdOptions" >
                <template v-slot:date="props" >
                    <FormatDate :date="props['row']['date']" />
                </template >
            </v-client-table >
        </div >
    </div >
</template >

<script >
import {ExclamationTriangleIcon, InboxArrowDownIcon, XMarkIcon} from '@heroicons/vue/24/outline'
import {TagIcon} from "@heroicons/vue/24/solid";
import FormatDate from '../components/FormatDate';
import {ChevronDownIcon} from "@heroicons/vue/20/solid";
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";
import Cookies from 'js-cookie';
import TaskModal from "./TaskModal.vue";

export default {
    components: {
        TaskModal,
        ChevronDownIcon,
        ExclamationTriangleIcon,
        FormatDate,
        InboxArrowDownIcon,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        TagIcon,
        XMarkIcon,
    },

    props: [
        "productId",
    ],

    data() {
        return {
            tabs: [
                "Übersicht",
                "MHD",
            ],
            currentTab: 'Übersicht',
            product: {
                mhd: []
            },
            tags: [],
            mhdColumns: [
                "date",
                "quantity",
            ],
            mhdOptions: {
                headings: {
                    "date": "MHD",
                    "quantity": "Anzahl",
                },
                texts: {
                    count: '{from} bis {to} von {count} Einträgen|{count} Einträge| Ein Eintrag',
                    noResults: 'Keine Einträge vorhanden',
                    loading: 'Wird geladen',
                    filterPlaceholder: 'Suchen',
                },
                perPage: 5,
            },
        };
    },

    methods: {
        close() {
            this.$emit('modal-close');
        },

        setTag(tag) {
            this.product.tag = tag.name;
            this.updateProductTag();
        },

        unsetTag() {
            this.product.tag = null;
            this.updateProductTag();
        },

        updateProductTag() {
            axios.put(`/api/products/${this.productId}/tag`, {
                tag: this.product.tag
            })
                .then(response => {
                    this.$emit('update');
                })
                .catch(error => {
                    console.log("Error updating product", error);
                });
        },
    },

    mounted() {
        this.tags = typeof Cookies.get('tags') === 'string' && typeof Cookies.get('tags') !== 'undefined' ? JSON.parse(Cookies.get('tags')) : [];
        axios.get('/api/products/' + this.productId)
            .then(response => {
                this.product = response.data.data
            })
            .catch(error => {
                console.log("Error fetching orders", error);
            });
    },
}
</script >
