<template>
    <div class="mt-8 flex justify-between items-center px-4 sm:px-6 lg:px-8">
        <a v-if="messageButtonVisible" :href="`mailto:support@beckmannsys.com?subject=${referenceType} ${referenceId}`"
                class="rounded-md border border-secondary bg-white py-2 px-4 text-sm text-secondary shadow-sm hover:text-secondary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 flex items-center">
            <ChatBubbleBottomCenterTextIcon class="h-6 w-6 inline-block mr-2"/> Nachricht ans Team
        </a>
        <a v-if="cancelButtonVisible" :href="`mailto:support@beckmannsys.com?subject=Storno-Anfrage: ${referenceType} ${referenceId}`" type="button"
                class="rounded-md border border-primary bg-white py-2 px-4 text-sm text-primary shadow-sm                         hover:text-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 flex items-center">
            <XCircleIcon class="h-6 w-6 inline-block mr-2"/> Storno anfragen
        </a>
    </div>
</template>

<script>
import {ChatBubbleBottomCenterTextIcon, XCircleIcon} from '@heroicons/vue/24/outline';
export default {
    components: {
        ChatBubbleBottomCenterTextIcon,
        XCircleIcon,
    },

    computed: {
        messageButtonVisible() {
            return this.show.length == 0 || this.show.includes('message');
        },
        cancelButtonVisible() {
            return this.show.length == 0 || this.show.includes('cancel');
        },
    },

    props: {
        referenceType: {
            type: String,
            required: true,
        },
        referenceId: {
            required: true
        },
        show: {
            type: Array,
            default: [],
        }
    }
}
</script>
