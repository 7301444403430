<template >
    <div >
        <div class="text-gray-900 flex mb-2" >
            <div class="text-lg font-medium " >
                Wareneingang: {{ goods.id }}
            </div >
        </div >

        <div class="flex justify-end mb-4" >
            <div class="mr-5" >
                <Menu as="div" class="relative inline-block text-left" >
                    <div >
                        <MenuButton
                            class="h-10 inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sky-700 border border-sky-700 shadow-sm hover:bg-gray-50" >
                            <TagIcon class="h-5 inline-block mr-1" />
                            {{ goods.tag ?? 'Tag' }}
                            <ChevronDownIcon class="h-5 inline-block ml-1" ></ChevronDownIcon >
                        </MenuButton >
                    </div >

                    <transition enter-active-class="transition ease-out duration-100"
                                enter-from-class="transform opacity-0 scale-95"
                                enter-to-class="transform opacity-100 scale-100"
                                leave-active-class="transition ease-in duration-75"
                                leave-from-class="transform opacity-100 scale-100"
                                leave-to-class="transform opacity-0 scale-95" >
                        <MenuItems
                            class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" >
                            <div class="py-1" >
                                <MenuItem v-slot="{ active }" class="hover:bg-gray-100"
                                          :class="tag.name === goods.tag ? 'bg-gray-100' : ''" v-for="tag in tags"
                                          :key="tag" >
                                    <div class="flex justify-between px-4 py-2 text-sm" >
                                        <div @click="setTag(tag)" class="flex-1 flex items-center" >
                                            <div class="h-2 w-2 rounded-full mr-2"
                                                 :style="`background-color: ${tag.color};`" ></div >
                                            {{ tag.name }}
                                        </div >
                                        <div >
                                            <XMarkIcon @click="unsetTag"
                                                       class="cursor-pointer text-gray-600 h-5 inline-block"
                                                       v-if="tag.name === goods.tag" />
                                        </div >
                                    </div >
                                </MenuItem >
                            </div >
                        </MenuItems >
                    </transition >
                </Menu >
            </div >

            <div >
                <a :href="`/api/goods/${goodId}/csv/expanded`"
                   download
                   class="flex-1 h-10 inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-primary border border-primary shadow-sm hover:bg-gray-50"
                >
                    <InboxArrowDownIcon class="h-5 inline-block mr-1" />
                    Export .csv
                </a >
            </div >
        </div >

        <div class="flex text-gray-600 text-sm space-x-10" >
            <div >Erstellt:
                <FormatDate :date="goods.created_at" />
            </div >
            <div >Geliefert:
                <FormatDate :date="goods.delivered_at" />
            </div >
        </div >

        <hr class="my-5" >

        <div class="sm:hidden mb-5" >
            <label for="tabs" class="sr-only" >Select a tab</label >
            <select id="tabs" name="tabs"
                    v-model="currentTab"
                    class="block w-full rounded-md border-gray-300 focus:border-secondary focus:ring-secondary" >
                <option v-for="tab in tabs" @change="currentTab = tab" >{{ tab }}
                </option >
            </select >
        </div >
        <div class="hidden sm:block mb-5" >
            <nav class="flex space-x-4" aria-label="Tabs" >
                <span v-for="tab in tabs"
                      :class="[tab == currentTab ? 'bg-secondary text-white' : 'text-gray-500 hover:text-gray-700', 'px-3 py-2 font-medium text-sm rounded-md cursor-pointer']"
                      :aria-current="tab == currentTab ? 'page' : undefined"
                      @click="currentTab = tab" >{{ tab }}</span >
            </nav >
        </div >

        <hr class="my-5" >

        <div v-show="currentTab == 'Übersicht'" >
            <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4" >

                <div >
                    <label for="reference_order_id" class="block text-sm font-medium text-gray-700" >Bezugsauftragsnummer</label >
                    <div class="mt-1" >
                        <input disabled="disabled" v-model="goods.reference_order_id" type="text"
                               id="reference_order_id" name="order_id"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-indigo-500 sm:text-sm" />
                    </div >
                </div >
                <div >
                    <label for="external_id" class="block text-sm font-medium text-gray-700" >Fremdbelegnummer</label >
                    <div class="mt-1" >
                        <input disabled="disabled" v-model="goods.external_id" type="text" id="external_id"
                               name="external_id"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                    </div >
                </div >
                <div >
                    <label for="quantity" class="block text-sm font-medium text-gray-700" >Gesamtmenge</label >
                    <div class="mt-1" >
                        <input disabled="disabled" v-model="goods.quantity" type="text" id="quantity" name="quantity"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                    </div >
                </div >
                <div >
                    <label for="delivered" class="block text-sm font-medium text-gray-700" >Gelieferte Menge</label >
                    <div class="mt-1" >
                        <input disabled="disabled" v-model="goods.delivered" type="text" id="delivered" name="delivered"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                    </div >
                </div >
                <div >
                    <label for="positions.length" class="block text-sm font-medium text-gray-700" >Anzahl
                        Positionen</label >
                    <div class="mt-1" >
                        <input disabled="disabled" :value="goods.positions.length" type="text" id="positions.length"
                               name="quantity"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                    </div >
                </div >
            </div >
        </div >

        <div v-show="currentTab == 'Positionen'" >
            <v-client-table ref="positionsTable" :data="this.goods.positions" :columns="positionsColumns"
                            :options="positionsOptions" />
        </div >
    </div >
</template >

<script >
import {ExclamationTriangleIcon, InboxArrowDownIcon, XMarkIcon} from '@heroicons/vue/24/outline'
import FormatDate from '../components/FormatDate';
import {ChevronDownIcon} from "@heroicons/vue/20/solid";
import {TagIcon} from "@heroicons/vue/24/solid";
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";
import Cookies from "js-cookie";

export default {
    components: {
        XMarkIcon,
        ChevronDownIcon,
        TagIcon,
        ExclamationTriangleIcon,
        FormatDate,
        InboxArrowDownIcon,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
    },

    props: [
        "goodId",
    ],

    data() {
        return {
            tabs: [
                "Übersicht",
                "Positionen",
            ],
            currentTab: 'Übersicht',
            positionsColumns: [
                "product",
                "name",
                "gtin",
                "sku",
                "quantity",
                "delivered",
            ],
            tags: [],
            positionsOptions: {
                headings: {
                    "product": "Artikel",
                    "name": "Name",
                    "gtin": "GTIN",
                    "sku": "SKU",
                    "quantity": "Menge",
                    "delivered": "Geliefert",
                },
                texts: {
                    count: '{from} bis {to} von {count} Einträgen|{count} Einträge| Ein Eintrag',
                    noResults: 'Keine Einträge vorhanden',
                    loading: 'Wird geladen',
                    filterPlaceholder: 'Suchen',
                },
                templates: {
                    "quantity": function (h, row, index) {
                        return Math.round(row["quantity"]);
                    },
                    "delivered": function (h, row, index) {
                        return Math.round(row["delivered"]);
                    },
                },
                perPage: 5,
            },
            goods: {
                positions: []
            }
        };
    },

    methods: {
        close() {
            this.$emit('modal-close');
        },

        setTag(tag) {
            this.goods.tag = tag.name;
            this.updateProductTag();
        },

        unsetTag() {
            this.goods.tag = null;
            this.updateProductTag();
        },

        updateProductTag() {
            axios.put(`/api/goods/${this.goodId}/tag`, {
                tag: this.goods.tag
            })
                .then(response => {
                    this.$emit('update');
                })
                .catch(error => {
                    console.log("Error updating good", error);
                });
        },

    },

    mounted() {
        this.tags = typeof Cookies.get('tags') === 'string' && typeof Cookies.get('tags') !== 'undefined' ? JSON.parse(Cookies.get('tags')) : [];

        axios.get('/api/goods/' + this.goodId)
            .then(response => {
                this.goods = response.data.data
            })
            .catch(error => {
                console.log("Error fetching orders", error);
            });

    },
}
</script >
