<template >
    <div >
        <div class="text-gray-900 flex mb-2" >
            <div class="text-lg font-medium " >
                Retoure: {{ rma.id }}
            </div >
        </div >

        <div class="flex justify-end mb-4" >
            <div class="mr-5" >
                <Menu as="div" class="relative inline-block text-left" >
                    <div >
                        <MenuButton
                            class="h-10 inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sky-700 border border-sky-700 shadow-sm hover:bg-gray-50" >
                            <TagIcon class="h-5 inline-block mr-1" />
                            {{ rma.tag ?? 'Tag' }}
                            <ChevronDownIcon class="h-5 inline-block ml-1" ></ChevronDownIcon >
                        </MenuButton >
                    </div >

                    <transition enter-active-class="transition ease-out duration-100"
                                enter-from-class="transform opacity-0 scale-95"
                                enter-to-class="transform opacity-100 scale-100"
                                leave-active-class="transition ease-in duration-75"
                                leave-from-class="transform opacity-100 scale-100"
                                leave-to-class="transform opacity-0 scale-95" >
                        <MenuItems
                            class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" >
                            <div class="py-1" >
                                <MenuItem v-slot="{ active }" class="hover:bg-gray-100"
                                          :class="tag.name === rma.tag ? 'bg-gray-100' : ''" v-for="tag in tags"
                                          :key="tag" >
                                    <div class="flex justify-between px-4 py-2 text-sm" >
                                        <div @click="setTag(tag)" class="flex-1 flex items-center" >
                                            <div class="h-2 w-2 rounded-full mr-2"
                                                 :style="`background-color: ${tag.color};`" ></div >
                                            {{ tag.name }}
                                        </div >
                                        <div >
                                            <XMarkIcon @click="unsetTag"
                                                       class="cursor-pointer text-gray-600 h-5 inline-block"
                                                       v-if="tag.name === rma.tag" />
                                        </div >
                                    </div >
                                </MenuItem >
                            </div >
                        </MenuItems >
                    </transition >
                </Menu >
            </div >

            <div >
                <a :href="`/api/rma/${rmaId}/csv/expanded`"
                   download
                   class="flex-1 h-10 inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-primary border border-primary shadow-sm hover:bg-gray-50"
                >
                    <InboxArrowDownIcon class="h-5 inline-block mr-1" />
                    Export .csv
                </a >
            </div >
        </div >

        <div class="flex text-gray-600 text-sm space-x-10" >
            <div class="flex-1" >Datum Bearbeitung:
                <FormatDate :date="rma.created_at" />
            </div >
            <div class="flex-1" >Auftrag: {{ rma.order }}</div >
            <div class="flex-1" >Externe ID: {{ rma.external_order }}</div >
        </div >

        <div class="flex text-gray-600 text-sm space-x-10 mt-2" >
            <div class="flex-1" >Tage seit Versand: {{
                    rma.days_since_shipment ? rma.days_since_shipment + " Tage" : '-'
                }}
            </div >
            <div class="flex-1" ></div >
            <div class="flex-1" ></div >
        </div >

        <hr class="my-5" >

        <div class="sm:hidden mb-5" >
            <label for="tabs" class="sr-only" >Select a tab</label >
            <select id="tabs" name="tabs"
                    v-model="currentTab"
                    class="block w-full rounded-md border-gray-300 focus:border-secondary focus:ring-secondary" >
                <option v-for="tab in tabs" @change="currentTab = tab" >{{ tab }}
                </option >
            </select >
        </div >
        <div class="hidden sm:block mb-5" >
            <nav class="flex space-x-4" aria-label="Tabs" >
                <span v-for="tab in tabs"
                      :class="[tab == currentTab ? 'bg-secondary text-white' : 'text-gray-500 hover:text-gray-700', 'px-3 py-2 font-medium text-sm rounded-md cursor-pointer']"
                      :aria-current="tab == currentTab ? 'page' : undefined"
                      @click="currentTab = tab" >{{ tab }}</span >
            </nav >
        </div >

        <hr class="my-5" >

        <div v-show="currentTab == 'Positionen'" >
            <v-client-table ref="positionsTable" :data="this.rma?.positions" :columns="positionsColumns"
                            :options="positionsOptions" />
        </div >

        <div v-show="currentTab == 'Absender'" >
            <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4" >
                <div >
                    <label for="firstName" class="block text-sm font-medium text-gray-700" >Vorname</label >
                    <div class="mt-1" >
                        <input disabled v-model="rma.customer.first_name" type="text" id="firstName" name="firstName"
                               autocomplete="given-name"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-indigo-500 sm:text-sm" />
                    </div >
                </div >
                <div >
                    <label for="lastName" class="block text-sm font-medium text-gray-700" >Nachname</label >
                    <div class="mt-1" >
                        <input disabled v-model="rma.customer.last_name" type="text" id="lastName" name="lastName"
                               autocomplete="last-name"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                    </div >
                </div >
                <div >
                    <label for="customer_id" class="block text-sm font-medium text-gray-700" >Kundennummer (für B2B
                        Orders)</label >
                    <div class="mt-1" >
                        <input disabled v-model="rma.customer.customer_id" type="text" id="customer_id"
                               name="customer_id"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                    </div >
                </div >
                <div >
                    <label for="company" class="block text-sm font-medium text-gray-700" >Firma</label >
                    <div class="mt-1" >
                        <input disabled v-model="rma.customer.company" type="text" id="company" name="company"
                               autocomplete="company"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                    </div >
                </div >
                <div >
                    <label for="companySuffix" class="block text-sm font-medium text-gray-700" >Firmenzusatz</label >
                    <div class="mt-1" >
                        <input disabled v-model="rma.customer.company_suffix" type="text" id="companySuffix"
                               name="companySuffix"
                               autocomplete="company-suffix"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                    </div >
                </div >
                <div >
                    <label for="address" class="block text-sm font-medium text-gray-700" >Straße + Hausnummer</label >
                    <div class="mt-1" >
                        <input disabled v-model="rma.customer.address" type="text" id="address" name="address"
                               autocomplete="address"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                    </div >
                </div >
                <div >
                    <label for="addressSuffix" class="block text-sm font-medium text-gray-700" >Adresszusatz</label >
                    <div class="mt-1" >
                        <input disabled v-model="rma.customer.address_supplement" type="text" id="addressSuffix"
                               name="addressSuffix" autocomplete="address"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                    </div >
                </div >
                <div >
                    <label for="city" class="block text-sm font-medium text-gray-700" >Stadt</label >
                    <div class="mt-1" >
                        <input disabled v-model="rma.customer.city" type="text" id="city" name="city"
                               autocomplete="city"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                    </div >
                </div >
                <div >
                    <label for="zipcode" class="block text-sm font-medium text-gray-700" >PLZ</label >
                    <div class="mt-1" >
                        <input disabled v-model="rma.customer.postal_code" type="text" id="zipcode" name="zipcode"
                               autocomplete="zip-code"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                    </div >
                </div >
                <div >
                    <label for="country" class="block text-sm font-medium text-gray-700" >Land</label >
                    <div class="mt-1" >
                        <input disabled v-model="rma.customer.country" type="text" id="zipcode" name="country"
                               autocomplete="country"
                               class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                    </div >
                </div >
            </div >
        </div >
        <div v-show="currentTab == 'Kommentar'" >
            <div class="mt-4" >
                <div >
                    <label for="commentExternal" class="block text-sm font-medium text-gray-700" >Kommentar</label >
                    <div class="mt-1" >
                        <textarea disabled="disabled" v-model="rma.comment_external" type="text" id="commentExternal"
                                  name="commentExternal"
                                  class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                    </div >
                </div >
            </div >
        </div >

    </div >
</template >

<script >
import {ExclamationTriangleIcon, InboxArrowDownIcon, XMarkIcon} from '@heroicons/vue/24/outline'
import {TagIcon} from "@heroicons/vue/24/solid";
import FormatDate from '../components/FormatDate';
import {ChevronDownIcon} from "@heroicons/vue/20/solid";
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";
import Cookies from 'js-cookie';

export default {
    components: {
        ChevronDownIcon,
        TagIcon,
        ExclamationTriangleIcon,
        FormatDate,
        InboxArrowDownIcon,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        XMarkIcon,
    },

    props: [
        "rmaId",
    ],

    data() {
        return {
            tabs: [
                "Positionen",
                "Absender",
                "Kommentar",
            ],
            currentTab: 'Positionen',
            tags: [],
            positionsColumns: [
                "product",
                "name",
                "reason",
                "quantity",
                "condition"
            ],
            positionsOptions: {
                headings: {
                    "product": "Artikel",
                    "name": "Name",
                    "reason": "Rückgabegrund",
                    "quantity": "Menge",
                    "condition": "Zustand"
                },
                texts: {
                    count: '{from} bis {to} von {count} Einträgen|{count} Einträge| Ein Eintrag',
                    noResults: 'Keine Einträge vorhanden',
                    loading: 'Wird geladen',
                    filterPlaceholder: 'Suchen',
                },
                templates: {
                    "quantity": function (h, row, index) {
                        return Math.round(row["quantity"]);
                    },
                },
                perPage: 5,
            },
            rma: {
                customer: [],
                positions: [],
                tag: null
            }
        };
    },

    methods: {
        close() {
            this.$emit('modal-close');
        },

        setTag(tag) {
            this.rma.tag = tag.name;
            this.updateRmaTag();
        },

        unsetTag() {
            this.rma.tag = null;
            this.updateRmaTag();
        },

        updateRmaTag() {
            axios.put(`/api/rma/${this.rmaId}/tag`, {
                tag: this.rma.tag
            })
                .then(response => {
                    this.$emit('update');
                })
                .catch(error => {
                    console.log("Error updating rma", error);
                });
        },
    },

    mounted() {
        this.tags = typeof Cookies.get('tags') === 'string' && typeof Cookies.get('tags') !== 'undefined' ? JSON.parse(Cookies.get('tags')) : [];
        axios.get('/api/rma/' + this.rmaId)
            .then(response => {
                this.rma = response.data.data
                this.$emit('opened');
            })
            .catch(error => {
                console.log("Error fetching orders", error);
            });
    },
}
</script >
