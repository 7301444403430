<template>
    <Menu as="div" class="relative inline-block text-left">
        <div>
            <MenuButton class="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none">
                <span class="sr-only">Open options</span>
                <div>
                    <div v-if="unreadNotifications.length" :class="['absolute p-1 text-white bg-red-500 rounded top-[-5px] text-[11px] line-height leading-[10px]', unreadNotifications.length > 9 ? 'right-[-9px]' : '!right-[-3px]']">{{ unreadNotifications.length > 9 ? '9+' : unreadNotifications.length }}</div>
                    <BellIcon class="h-6 w-6 text-gray-600 hover:text-gray-900" aria-hidden="true" />
                </div>
            </MenuButton>
        </div>

        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="absolute right-0 sm:right-auto left-auto sm:left-0 z-10 mt-2 w-[300px] sm:w-[500px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div class="flex justify-between pt-3 pb-2 px-4 border-b border-gray-100 bg-[#F1F5F9] ">
                    <div class="font-bold text-gray-800">Benachrichtigungen</div>

                    <div class="text-sm cursor-pointer text-gray-500" @click="markAllAsRead">
                        Alle als gelesen markieren
                    </div>
                </div>
                <div class="py-1">
                    <MenuItem v-slot="{ active }"
                              v-for="notification in notifications"
                              :key="notification.reference_id+notification.reference_type+notification.created_at"
                    >
                        <div @click="goToNotification(notification)"
                             :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm cursor-pointer', !notification.read ? 'bg-gray-100' : '']">
                            <div class="flex justify-between gap-4">
                                <div class="flex items-center flex-grow-0">
                                    <EnvelopeOpenIcon v-if="notification.read" class="h-5 w-5 text-gray-600 hover:text-gray-900" />
                                    <EnvelopeIcon v-else class="h-5 w-5 text-gray-600 hover:text-gray-900" />
                                </div>
                                <div class="flex-grow">
                                    <div :class="[!notification.read ? 'font-semibold' : '']">{{ notification.message }}</div>

                                    <div class="text-gray-500">
                                        {{ new Date(notification.created_at).toLocaleString('de-DE', { dateStyle: 'short', timeStyle: 'short', hour12: false }) }} Uhr
                                    </div>

                                </div>
                                <div class="flex items-center flex-grow-0" v-if="!notification.read">
                                    <div @click.prevent="markAsRead(notification)">
                                        <EnvelopeOpenIcon class="h-5 w-5 text-gray-600 hover:text-gray-900" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </MenuItem>
                </div>
            </MenuItems>
        </transition>
    </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { EnvelopeIcon } from '@heroicons/vue/20/solid'
import { BellIcon, EnvelopeOpenIcon } from '@heroicons/vue/24/outline';
export default {
    name: 'NotificationMenu',

    components: {
        BellIcon,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        EnvelopeIcon,
        EnvelopeOpenIcon,
    },

    data () {
        return {
            notifications: [],
            interval: null,
        }
    },

    methods: {
        getNotifications () {
            axios.get('/api/notifications')
                .then((response) => {
                    this.notifications = response.data
                });
        },
        markAsRead(notification) {
            axios.get(`/api/notifications/${notification.id}/mark-as-read`)
                .then(() => {
                    this.getNotifications();
                });
        },

        markAllAsRead() {
            axios.get(`/api/notifications/mark-all-as-read`)
                .then(() => {
                    this.getNotifications();
                });
        },

        goToNotification(notification) {
            if (!notification.read) {
                this.markAsRead(notification);
            }
            this.$router.push(`/${notification.reference_type}/${notification.reference_id}`);
        }
    },

    computed: {
        unreadNotifications () {
            return this.notifications.filter(notification => !notification.read)
        },

        currentRoutePath() {
            return this.$route.path;
        },
    },

    beforeDestroy () {
        clearInterval(this.interval)
    },

    mounted () {
        if (!isAdmin() && !isManager() || !isCustomer()) {
            return;
        }

        // fetch notifications every 10 minutes
        this.interval = setInterval(this.getNotifications, 600000);

        this.getNotifications()
    }
}
</script>
