<template >
    <form >
        <div class="text-gray-900 flex mb-5" >
            <div class="text-lg font-medium " >
                Mein Account
            </div >
        </div >

        <div class="sm:hidden mb-5" >
            <label for="tabs" class="sr-only" >Select a tab</label >
            <select id="tabs" name="tabs"
                    v-model="currentTab"
                    class="block w-full rounded-md border-gray-300 focus:border-secondary focus:ring-secondary" >
                <option v-for="tab in tabs" @change="currentTab = tab" >{{ tab }}
                </option >
            </select >
        </div >
        <div class="hidden sm:block mb-5" >
            <nav class="flex space-x-4" aria-label="Tabs" >
                <span v-for="tab in tabs"
                      :class="[tab == currentTab ? 'bg-secondary text-white' : 'text-gray-500 hover:text-gray-700', 'px-3 py-2 font-medium text-sm rounded-md cursor-pointer']"
                      :aria-current="tab == currentTab ? 'page' : undefined"
                      @click="currentTab = tab" >
                    {{ tab }}
                </span >
                <a v-if="user.dhl_invoices" :href="user.dhl_invoices" target="_blank"
                   class="text-white bg-secondary px-3 py-2 font-medium text-sm rounded-md cursor-pointer flex items-center" >
                    <span >DHL Rechnungen</span >
                    <ArrowTopRightOnSquareIcon class="h-3.5 ml-2" />
                </a >
            </nav >
        </div >

        <div class="flex flex-col justify-between h-full" >
            <div v-if="validationErrors.hints.length" >
                <ul class="list-disc list-inside grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-x-4 text-primary-dark" >
                    <li v-for="hint in validationErrors.hints" >{{ hint }}</li >
                </ul >
                <hr class="my-5" >
            </div >

            <div v-show="currentTab == 'Account'" >
                <div class="text-gray-900 flex mb-5 font-medium" >
                    Benutzer
                </div >
                <div class="mt-4 grid grid-cols-1" >
                    <div class="mb-2" >
                        <label for="username" class="block text-sm font-medium text-gray-700" >Benutzername</label >
                        <div class="mt-1" >
                            <input disabled type="text"
                                   class="bg-gray-100 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                   :value="user.username" />
                        </div >
                    </div >
                    <div class="mb-2" >
                        <label for="email" class="block text-sm font-medium text-gray-700" >E-Mail</label >
                        <div class="mt-1" >
                            <input v-model="user.email" type="text" id="email" name="email"
                                   autocomplete="off"
                                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                   :class="validationErrors.fields.email ? ['!border-primary'] : []"
                                   @change="validateAgain" />
                        </div >
                    </div >

                    <hr class="my-5" >

                    <div >
                        <div class="text-gray-900 flex mb-5 font-medium" >
                            Passwort
                        </div >

                        <div class="mb-2" >
                            <label for="password" class="block text-sm font-medium text-gray-700" >Aktuelles
                                Passwort</label >
                            <div class="mt-1" >
                                <input v-model="user.password" type="password" id="password" name="password"
                                       autocomplete="current-password"
                                       class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                       :class="validationErrors.fields.password ? ['!border-primary'] : []"
                                       @change="validateAgain" />
                            </div >
                        </div >

                        <div class="mb-2" >
                            <label for="newPassword" class="block text-sm font-medium text-gray-700" >Neues
                                Passwort</label >
                            <div class="mt-1" >
                                <input v-model="user.newPassword" type="password" id="newPassword"
                                       name="newPassword"
                                       autocomplete="new-password"
                                       class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                       :class="validationErrors.fields.newPassword ? ['!border-primary'] : []"
                                       @change="validateAgain" />
                            </div >
                        </div >

                        <div class="mb-2" >
                            <label for="newPasswordRepeat" class="block text-sm font-medium text-gray-700" >Neues
                                Passwort Wiederholen</label >
                            <div class="mt-1" >
                                <input v-model="user.newPasswordRepeat" type="password" id="newPasswordRepeat"
                                       name="newPasswordRepeat"
                                       autocomplete="new-password"
                                       class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                       :class="validationErrors.fields.passwordRepeat ? ['!border-primary'] : []"
                                       @change="validateAgain" />
                            </div >
                        </div >
                    </div >
                </div >
            </div >
            <div v-show="currentTab == 'Benachrichtigungen'" >
                <div class="text-gray-900 flex mb-5 font-medium" >
                    Benachrichtigungseinstellungen
                </div >
                <div class="mt-4 grid grid-cols-1" >
                    <div class="flex mb-3 gap-x-10" v-for="(notificationOption, index) in user.notification_options"
                         :key="notificationOption.notification" >
                        <div class="flex items-center w-1/3 " >
                            {{ notificationOption.notification }}
                        </div >
                        <div >
                            <fieldset >
                                <legend class="sr-only" >Benachrichtigungs-Typ</legend >
                                <div class="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0" >
                                    <div class="flex items-center" >
                                        <input :id="`none-${index}`" :name="notificationOption.notification"
                                               type="radio" :checked="notificationOption.type == 'none'"
                                               class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                        <label :for="`none-${index}`"
                                               class="ml-3 block text-sm font-medium leading-6 text-gray-900" >Keine</label >
                                    </div >
                                    <div class="flex items-center" >
                                        <input :id="`email-${index}`" :name="notificationOption.notification"
                                               type="radio" :checked="notificationOption.type == 'email'"
                                               class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                        <label :for="`email-${index}`"
                                               class="ml-3 block text-sm font-medium leading-6 text-gray-900" >E-Mail</label >
                                    </div >
                                    <div class="flex items-center" >
                                        <input :id="`in-app-${index}`" :name="notificationOption.notification"
                                               type="radio" :checked="notificationOption.type == 'in-app'"
                                               class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                        <label :for="`in-app-${index}`"
                                               class="ml-3 block text-sm font-medium leading-6 text-gray-900" >In-App</label >
                                    </div >
                                    <div class="flex items-center" >
                                        <input :id="`both-${index}`" :name="notificationOption.notification"
                                               type="radio" :checked="notificationOption.type == 'both'"
                                               class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                        <label :for="`both-${index}`"
                                               class="ml-3 block text-sm font-medium leading-6 text-gray-900" >Beides</label >
                                    </div >
                                </div >
                            </fieldset >
                        </div >
                    </div >
                </div >
            </div >

            <div v-show="currentTab == 'Tags'" >
                <div class="mt-4 grid grid-cols-1" >
                    <TagManagement />
                </div >
            </div >
        </div >

        <div class="mt-8 flex justify-between" v-if="currentTab !== 'Tags'" >
            <button type="button"
                    @click="$emit('close-me')"
                    class="rounded-md border border-transparent bg-secondary py-2 px-4 text-sm text-white shadow-sm hover:bg-secondary-dark focus:outline-none focus:ring-2 focus:bg-secondary-dark focus:ring-offset-2 focus:ring-offset-gray-50" >
                Abbrechen
            </button >
            <button type="button"
                    @click="updateUser"
                    class="rounded-md border border-transparent bg-primary py-2 px-4 text-sm text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:bg-primary-dark focus:ring-offset-2 focus:ring-offset-gray-50" >
                Speichern
            </button >
        </div >
    </form >
</template >

<script >
import {
    ArrowTopRightOnSquareIcon,
    CheckIcon,
    ExclamationTriangleIcon,
    InformationCircleIcon,
    PencilSquareIcon,
    PlusIcon,
    TrashIcon
} from '@heroicons/vue/24/outline'
import Steps from './Steps.vue'
import Multiselect from '@vueform/multiselect'
import TagManagement from "./TagManagement.vue";


export default {
    components: {
        TagManagement,
        PlusIcon,
        TrashIcon,
        CheckIcon,
        ExclamationTriangleIcon,
        InformationCircleIcon,
        PencilSquareIcon,
        Steps,
        Multiselect,
        ArrowTopRightOnSquareIcon,
    },

    props: [
        "userId",
    ],

    data() {
        return {
            submitSuccess: undefined,
            step: 0,
            submitError: null,
            validationErrors: {
                fields: {},
                hints: [],
                message: null,
            },
            user: {
                "customers": [],
                "categories": [],
                "dhl_invoices": '',
                "status": '',
                "role": '',
                "username": '',
                "email": '',
                // TODO: Remove this when backend is ready
                "notification_options": [
                    {
                        notification: 'Neuer Adressfehler',
                        type: 'both'
                    },
                    {
                        notification: 'Neue Überbestellung',
                        type: 'both'
                    },
                    {
                        notification: 'Auftrag wurde storniert',
                        type: 'both'
                    },
                    {
                        notification: 'Neue Retouren',
                        type: 'both'
                    },
                    {
                        notification: 'Lieferung in Bearbeitung',
                        type: 'both'
                    },
                    {
                        notification: 'Lieferung abgeschlossen',
                        type: 'both'
                    },
                    {
                        notification: 'Negativbestand Artikel',
                        type: 'both'
                    },
                    {
                        notification: 'Neuer Auftrag',
                        type: 'both'
                    },
                ],
            },
            tabs: [
                "Account",
                // "Benachrichtigungen",
                "Tags",
            ],
            currentTab: 'Account',
        };
    },

    methods: {
        close() {
            this.$emit('modal-close');
        },

        resetValidation() {
            this.validationErrors = {
                fields: {
                    positions: []
                },
                hints: [],
                message: null,
                validated: false,
                valid: true,
            }
        },

        validate() {
            this.resetValidation();
            if (!this.user.email) {
                this.validationErrors.fields.email = true;
                this.validationErrors.hints.push('E-Mail-Adresse fehlt');
                this.validationErrors.valid = false;
            }
            if ((this.user.newPassword || this.user.newPasswordRepeat) && !this.user.password) {
                this.validationErrors.fields.password = true;
                this.validationErrors.hints.push('Aktuelles Passwort muss zum Ändern des Passwortes angegeben werden');
                this.validationErrors.valid = false;
            }
            if (this.user.newPassword != this.user.newPasswordRepeat) {
                this.validationErrors.fields.newPassword = this.validationErrors.fields.newPasswordRepeat = true;
                this.validationErrors.hints.push('Passwort stimmt nicht überein');
                this.validationErrors.valid = false;
            }
            this.validationErrors.validated = true;
            return this.validationErrors.valid;
        },

        validateAgain() {
            if (this.validationErrors.validated) {
                this.validate();
            }
        },

        updateUser() {
            if (this.validate()) {
                axios.post('/api/my-account', this.user)
                    .then(response => {
                        this.submitSuccess = true;
                        this.$emit('close-me');
                    })
                    .catch(error => {
                        this.$swal({
                            title: 'Änderungen nicht gespeichert',
                            text: error.message,
                            icon: 'error',
                            showConfirmButton: true,
                            confirmButtonText: 'OK',
                        })
                    });
            }
        },

        fetchUser() {
            axios.get('/api/my-account')
                .then(response => {
                    this.user = response.data.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },

        async confirmDelete() {
            try {
                let confirmation = await this.$swal({
                    text: `Benutzer ${this.user.username} wirklich löschen?`,
                    icon: 'warning',
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Löschen',
                    cancelButtonText: 'Abbrechen',
                })

                if (confirmation.isConfirmed) {
                    let response = await axios.delete('/api/users/' + this.user.id);
                    this.$swal({
                        text: `Benutzer ${this.user.username} gelöscht`,
                        icon: 'success',
                        showConfirmButton: true,
                        confirmButtonText: 'OK',
                    });
                    this.$emit('close-me');
                }
            } catch (error) {
                this.$swal({
                    title: 'Fehler',
                    text: error.message,
                    showConfirmButton: true,
                });
            }


        }
    },

    mounted() {
        this.fetchUser();
    },
}
</script >
