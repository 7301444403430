<template >
    <div
        class="rounded-xl bg-slate-50 border border-slate-200 px-2 py-1 flex items-center justify-center space-x-3 h-10" >
        <div v-if="!isCustomer && (task.status !== 'cancelled' && task.status !== 'completed')" >
            <CheckBadgeIcon class="h-7 w-7 text-green-500 hover:text-green-600" @click="completeTask" />
        </div >

        <div v-if="!isCustomer" >
            <ChatBubbleLeftEllipsisIcon class="h-7 w-7 text-secondary hover:text-secondary-dark"
                                        @click="showCommentModal = true" />
        </div >

        <div v-if="!isCustomer" >
            <ArrowLeftCircleIcon class="h-7 w-7 text-gray-400 hover:text-gray-500" @click="resetTask" />
        </div >

        <div v-if="task.status !== 'cancelled' && task.status !== 'completed'" >
            <XCircleIcon class="h-7 w-7 text-red-400 hover:text-red-500" @click="cancelTask" />
        </div >
    </div >
    <Modal :show="this.showCommentModal" @modalClose="this.showCommentModal = false" >
        <template v-slot:body >
            <TaskCommentModal :task="task" @commented="this.showCommentModal = false" />
        </template >
    </Modal >
</template >

<script >
import {ArrowLeftCircleIcon, ChatBubbleLeftEllipsisIcon, CheckBadgeIcon, XCircleIcon} from "@heroicons/vue/24/solid";
import OrderDetails from "./OrderDetails.vue";
import ContactButtons from "./ContactButtons.vue";
import TaskCommentModal from "./TaskCommentModal.vue";
import Modal from "./Modal.vue";
import TaskActions from '../mixins/taskActions.js';

export default {
    components: {
        Modal, ContactButtons, OrderDetails,
        CheckBadgeIcon,
        ChatBubbleLeftEllipsisIcon,
        ArrowLeftCircleIcon,
        XCircleIcon,
        TaskCommentModal,
    },

    mixins: [TaskActions],

    emits: ['updated'],

    props: {
        task: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            showCommentModal: false,
        }
    },

    computed: {
        isCustomer() {
            return window.isCustomer();
        },
    },
}
</script >
