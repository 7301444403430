import {createApp} from 'vue';
import router from './router';
import App from './pages/App.vue';
import {ClientTable, ServerTable} from "v-tables-3";
import axios from './helpers/axios.js';
import dayjs from "dayjs";
import 'dayjs/locale/de';
import VueSweetalert2 from 'vue-sweetalert2';
import Cookies from 'js-cookie'
import moment from "moment";

window.axios = axios;
window.dayjs = dayjs;
dayjs.locale('de');
moment.locale('de');

window.isTeam = () => {
    return Cookies.get('level') === 'team';
}

window.isCustomer = () => {
    return Cookies.get('level') === 'customer';
}

window.isManager = () => {
    return Cookies.get('level') === 'manager';
}

window.isAdmin = () => {
    return Cookies.get('level') === 'admin';
}

const options = {
    sortIcon: {
        base: "",
        is: "",
        up: "sort-asc",
        down: "sort-desc",
    },
}

createApp({
    components: {
        App,
    },
})
    .use(router)
    .use(ServerTable, options)
    .use(ClientTable, options)
    .use(VueSweetalert2)
    .mount('#app');
