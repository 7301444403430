<template >
    <div class="w-full border border-gray-200 rounded-2xl" >
        <div class="flex justify-between items-center px-4 py-3" >
            <div class="font-light flex flex-row space-x-5" >
                <button type="button"
                        :class="['ml-2 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-base leading-4 text-gray-700 hover:bg-slate-100 border border-slate-200', {'!bg-slate-200 !border-slate-300': selectedFilter === 'all'}]"
                        @click="changeFilter('all')" >
                    <span >Alle</span >
                </button >

                <button type="button"
                        :class="['ml-2 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-base leading-4 text-gray-700 hover:bg-slate-100 border border-slate-200', {'!bg-slate-200 !border-slate-300': selectedFilter === 'completed'}]"
                        @click="changeFilter('completed')" >
                    <span >Erfolgreich</span >
                </button >

                <button type="button"
                        :class="['ml-2 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-base leading-4 text-gray-700 hover:bg-slate-100 border border-slate-200', {'!bg-slate-200 !border-slate-300': selectedFilter === 'created'}]"
                        @click="changeFilter('created')" >
                    <span >Angelegt</span >
                </button >

                <button type="button"
                        :class="['ml-2 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-base leading-4 text-gray-700 hover:bg-slate-100 border border-slate-200', {'!bg-slate-200 !border-slate-300': selectedFilter === 'cancelled'}]"
                        @click="changeFilter('cancelled')" >
                    <span >Abgebrochen</span >
                </button >
            </div >

            <div >
                <Menu as="div" class="relative inline-block text-left" >
                    <div >
                        <MenuButton
                            class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-gray-100" >
                            {{ options.perPage }} Einträge
                            <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                        </MenuButton >
                    </div >

                    <transition enter-active-class="transition ease-out duration-100"
                                enter-from-class="transform opacity-0 scale-95"
                                enter-to-class="transform opacity-100 scale-100"
                                leave-active-class="transition ease-in duration-75"
                                leave-from-class="transform opacity-100 scale-100"
                                leave-to-class="transform opacity-0 scale-95" >
                        <MenuItems v-model="options.perPage"
                                   class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" >
                            <div class="py-1" >
                                <MenuItem v-slot="{ active }" >
                                    <div @click="options.perPage = 5"
                                         :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']" >
                                        5 Einträge
                                    </div >
                                </MenuItem >
                                <MenuItem v-slot="{ active }" >
                                    <div @click="options.perPage = 15"
                                         :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']" >
                                        15 Einträge
                                    </div >
                                </MenuItem >
                                <MenuItem v-slot="{ active }" >
                                    <div @click="options.perPage = 30"
                                         :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']" >
                                        30 Einträge
                                    </div >
                                </MenuItem >
                                <MenuItem v-slot="{ active }" >
                                    <div @click="options.perPage = 50"
                                         :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']" >
                                        50 Einträge
                                    </div >
                                </MenuItem >
                            </div >
                        </MenuItems >
                    </transition >
                </Menu >
            </div >
        </div >

        <div class="px-1 table-no-border" >
            <v-server-table
                url="/api/tasks"
                :columns="columns"
                :options="options"
                ref="table"
                @rowClick="showTaskDetailModal"
            >
                <template v-slot:updated_at="props" >
                    <div v-if="!isCustomer" >
                        <div >{{ moment(props.row.created_at).fromNow() }}</div >
                        <div class="font-medium" >{{ moment(props.row.created_at).format('DD.MM.YYYY HH:mm') }}</div >
                    </div >

                    <div v-if="isCustomer" >
                        <div class="font-medium" >{{ moment(props.row.created_at).format('DD.MM.YYYY') }}</div >
                    </div >
                </template >

                <template v-slot:status="props" >
                    <span
                        :class="['px-2 py-1 text-xs font-medium rounded-full', statusBadgeClass(props.row.status)]" >
                        {{ translatedStatus(props.row.status) }}
                    </span >
                </template >

                <template v-slot:actions="props" >
                    <div class="do-not-click" >
                        <TaskAction :task="props.row" @updated="this.filter()" />
                    </div >
                </template >
            </v-server-table >
        </div >
    </div >

    <Modal :show="this.showDetailModal" @modalClose="this.showDetailModal = false" >
        <template v-slot:body >
            <TaskDetailModal :task="task" />
        </template >

        <template v-slot:footer >
            <div v-if="!isCustomer" class="mt-8 flex justify-end items-center px-4 sm:px-6 lg:px-8 space-x-5" >
                <button type="button"
                        @click="completeTask(true)"
                        class="flex justify-center items-center bg-green-500 hover:bg-green-600 rounded-md px-3 py-2 text-white font-light h-10" >
                    Erledigt
                </button >

                <button type="button"
                        @click="resetTask(true)"
                        class="flex justify-center items-center bg-gray-400 hover:bg-gray-500 rounded-md px-3 py-2 text-white font-light h-10" >
                    Zurücksetzen
                </button >

                <button type="button"
                        @click="cancelTask(true)"
                        class="flex justify-center items-center bg-red-400 hover:bg-red-500 rounded-md px-3 py-2 text-white font-light h-10" >
                    Abbrechen
                </button >
            </div >
        </template >
    </Modal >
</template >

<script >
import TaskAction from "./TaskAction";
import {TruckIcon} from "@heroicons/vue/24/outline";
import moment from "moment";
import TaskDetailModal from "./TaskDetailModal.vue";
import Modal from "./Modal.vue";
import TaskActions from "../mixins/taskActions";
import {ChevronDownIcon} from "@heroicons/vue/20/solid";
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue'
import debounce from "debounce";

export default {
    components: {
        ChevronDownIcon,
        Modal, TaskDetailModal,
        TruckIcon,
        TaskAction,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
    },

    mixins: [TaskActions],

    data() {
        return {
            debouncedFilter: null,
            showDetailModal: false,
            task: null,
            tasks: [],
            selectedFilter: 'all',
            columns: [
                'updated_at',
                'user',
                'subject',
                'status',
                'actions'
            ],
            options: {
                orderBy: {
                    column: 'updated_at',
                    ascending: false,
                },
                headings: {
                    updated_at: 'Letzte Aktualisierung',
                    user: 'Nutzer',
                    subject: 'Aufgabe',
                    status: 'Status',
                    actions: 'Aktionen',
                },
                perPage: 5,
                texts: {
                    count: '{from} bis {to} von {count} Einträgen|{count} Einträge| Ein Eintrag',
                    filter: 'Filterergebnis:',
                    noResults: 'Keine Einträge vorhanden',
                    loading: 'Wird geladen',
                    filterPlaceholder: 'Suchen',
                },
                columnsClasses: {
                    actions: 'w-14',
                },
                rowClassCallback: (row) => {
                    if (row.status === 'created') {
                        return 'font-bold';
                    }
                },
                requestFunction(data) {
                    this.$parent.$parent.loadingSpinner = true;
                    data.limit = this.$parent.$parent.options.perPage;

                    if (this.$parent.$parent.selectedFilter) {
                        data['status'] = this.$parent.$parent.selectedFilter;
                    }

                    return axios.get(this.url, {
                        params: data
                    }).finally(() => {
                        this.$parent.$parent.loadingSpinner = false;
                    }).catch((e) => {
                        this.$parent.$parent.loadingSpinner = false;
                        this.dispatch('error', e);
                    });
                },
            },
        };
    },

    computed: {
        moment() {
            return moment
        },

        isCustomer() {
            return window.isCustomer();
        },
    },

    watch: {
        selectedFilter() {
            this.filter();
        },

        'options.perPage': {
            handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.$refs.table?.setLimit(this.options.perPage);
                }
            }
        },
    },

    methods: {
        statusBadgeClass(status) {
            return {
                'bg-slate-200 text-slate-800': status === 'created',
                'bg-green-200 text-green-800': status === 'completed',
                'bg-red-200 text-red-800': status === 'cancelled',
            }
        },

        translatedStatus(status) {
            return {
                'created': 'Erstellt',
                'completed': 'Erfolgreich',
                'cancelled': 'Abgebrochen',
            }[status];
        },

        changeFilter(filter) {
            this.selectedFilter = filter
        },

        showTaskDetailModal(event) {
            if (event.event.target.closest('.do-not-click')) {
                return;
            }

            this.task = event.row;
            this.showDetailModal = true;
        },

        filter() {
            this.debouncedFilter ? this.debouncedFilter() : this.filterTable();
        },

        filterTable() {
            this.$refs.table?.refresh();
        },
    },

    mounted() {
        this.debouncedFilter = debounce(() => {
            this.filterTable();
        }, 200);
    }
}
</script >
