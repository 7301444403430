<template >
    <div v-if="loadingSpinner"
         class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-slate-600 flex flex-col items-center justify-center opacity-70" >
        <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4" ></div >
        <h2 class="text-center text-white text-xl font-semibold" >Wird geladen...</h2 >
        <p class="w-1/3 text-center text-white" >Dies kann wenige Sekunden dauern. Bitte die Seite nicht schließen.</p >
    </div >

    <div >
        <div class="mt-4" >
            <div class="mb-4 flex justify-between items-center" >
                <div class="text-xl text-gray-600 flex items-center" >Kennzahlen</div >
                <div >
                    <vue-tailwind-datepicker
                        v-model="dateRangeMetrics"
                        as-single
                        use-range
                        input-classes="block w-full rounded-md border-gray-300 px-4 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                        :placeholder="dateRangeMetrics.startDate && dateRangeMetrics.endDate ? (dateRangeMetrics.startDate + ' - ' + dateRangeMetrics.endDate) : 'Datum auswählen'"
                        separator=" - "
                        :formatter="{date: 'DD.MM.YY', month: 'MMM'}"
                        :disable-date="(date) => date > new Date"
                        :options="{
                            shortcuts: {
                                today: 'Heute',
                                yesterday: 'Gestern',
                                past: period =>  `letzten ${period} Tage`,
                                currentMonth: 'Dieser Monat',
                                pastMonth: 'Letzter Monat'
                           },
                           footer: {
                            apply: 'Auswählen',
                            cancel: 'Abbrechen'
                          }
                        }"
                    />
                </div >
            </div >

            <div class="flex flex-wrap space-x-0 lg:space-x-5 space-y-5 lg:space-y-0 items-center" >
                <div
                    class="rounded bg-white border border-gray-200 p-4 flex flex-row lg:flex-1 cursor-pointer items-center" >
                    <div class="flex flex-col flex-1" >
                        <div class="flex flex-row items-center" >
                            <div class="p-2 bg-cyan-500 rounded mr-6" >
                                <ShoppingBagIcon class="text-white h-7" />
                            </div >

                            <div class="flex flex-col justify-center" >
                                <div class="text-gray-600 text-lg leading-6" >
                                    Aufträge importiert
                                </div >
                                <div class="font-semibold text-xl leading-tight" >
                                    {{ metrics.importedOrders }}
                                </div >
                            </div >
                        </div >
                    </div >
                </div >

                <div
                    class="rounded bg-white border border-gray-200 p-4 flex flex-row lg:flex-1 cursor-pointer items-center" >
                    <div class="flex flex-col flex-1" >
                        <div class="flex flex-row items-center" >
                            <div class="p-2 bg-cyan-500 rounded mr-6" >
                                <PresentationChartLineIcon class="text-white h-7" />
                            </div >

                            <div class="flex flex-col justify-center" >
                                <div class="text-gray-600 text-lg leading-6" >
                                    Artikel in Aufträgen
                                </div >
                                <div class="font-semibold text-xl leading-tight" >
                                    {{ metrics.articlesInOrders }}
                                </div >
                            </div >
                        </div >
                    </div >
                </div >

                <div
                    class="rounded bg-white border border-gray-200 p-4 flex flex-row lg:flex-1 cursor-pointer items-center" >
                    <div class="flex flex-col flex-1" >
                        <div class="flex flex-row items-center" >
                            <div class="p-2 bg-cyan-500 rounded mr-6" >
                                <ArrowPathRoundedSquareIcon class="text-white h-7" />
                            </div >

                            <div class="flex flex-col justify-center" >
                                <div class="text-gray-600 text-lg leading-6" >
                                    Retouren bearbeitet
                                </div >
                                <div class="font-semibold text-xl leading-tight" >
                                    {{ metrics.processedRmas }}
                                </div >
                            </div >
                        </div >
                    </div >
                </div >
            </div >

            <!--            <div class="mt-5 flex flex-wrap space-x-0 lg:space-x-5 space-y-5 lg:space-y-0 items-center" >-->
            <!--                <div-->
            <!--                    class="rounded bg-white border border-gray-200 p-4 flex flex-row lg:flex-1 cursor-pointer items-center" >-->
            <!--                    <div class="flex flex-col flex-1" >-->
            <!--                        <div class="flex flex-row items-center" >-->
            <!--                            <div class="p-2 bg-cyan-500 rounded mr-6" >-->
            <!--                                <ShoppingCartIcon class="text-white h-7" />-->
            <!--                            </div >-->

            <!--                            <div class="flex flex-col justify-center" >-->
            <!--                                <div class="text-gray-600 text-lg leading-6" >-->
            <!--                                    Artikel je Auftrag-->
            <!--                                </div >-->
            <!--                                <div class="font-semibold text-xl leading-tight" >-->
            <!--                                    {{ metrics.articlesPerOrder }}-->
            <!--                                </div >-->
            <!--                            </div >-->
            <!--                        </div >-->
            <!--                    </div >-->
            <!--                </div >-->

            <!--                <div-->
            <!--                    class="rounded bg-white border border-gray-200 p-4 flex flex-row lg:flex-1 cursor-pointer items-center" >-->
            <!--                    <div class="flex flex-col flex-1" >-->
            <!--                        <div class="flex flex-row items-center" >-->
            <!--                            <div class="p-2 bg-cyan-500 rounded mr-6" >-->
            <!--                                <ChartPieIcon class="text-white h-7" />-->
            <!--                            </div >-->

            <!--                            <div class="flex flex-col justify-center" >-->
            <!--                                <div class="text-gray-600 text-lg leading-6" >-->
            <!--                                    Retourenquote-->
            <!--                                </div >-->
            <!--                                <div class="font-semibold text-xl leading-tight" >-->
            <!--                                    {{ metrics.rmaRate }}-->
            <!--                                </div >-->
            <!--                            </div >-->
            <!--                        </div >-->
            <!--                    </div >-->
            <!--                </div >-->

            <!--                <div-->
            <!--                    class="rounded bg-white border border-gray-200 p-4 flex flex-row lg:flex-1 cursor-pointer items-center" >-->
            <!--                    <div class="flex flex-col flex-1" >-->
            <!--                        <div class="flex flex-row items-center" >-->
            <!--                            <div class="p-2 bg-cyan-500 rounded mr-6" >-->
            <!--                                <GlobeAltIcon class="text-white h-7" />-->
            <!--                            </div >-->

            <!--                            <div class="flex flex-col justify-center" >-->
            <!--                                <div class="text-gray-600 text-lg leading-6" >-->
            <!--                                    Top Land-->
            <!--                                </div >-->
            <!--                                <div class="font-semibold text-xl leading-tight" >-->
            <!--                                    {{ metrics.topCountry }}-->
            <!--                                </div >-->
            <!--                            </div >-->
            <!--                        </div >-->
            <!--                    </div >-->
            <!--                </div >-->
            <!--            </div >-->
        </div >

        <div class="mt-14" >
            <div class="flex justify-between items-center" >
                <div class="text-xl text-gray-600 mt-4 mb-4" >Entwicklung</div >
                <div >
                    <vue-tailwind-datepicker
                        v-model="dateRangeDevelopment"
                        as-single
                        use-range
                        input-classes="block w-full rounded-md border-gray-300 px-4 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                        :placeholder="dateRangeDevelopment.startDate && dateRangeDevelopment.endDate ? (dateRangeDevelopment.startDate + ' - ' + dateRangeDevelopment.endDate) : 'Datum auswählen'"
                        separator=" - "
                        :formatter="{date: 'DD.MM.YY', month: 'MMM'}"
                        :disable-date="(date) => date > new Date"
                        :options="{
                            shortcuts: {
                                today: 'Heute',
                                yesterday: 'Gestern',
                                past: period =>  `letzten ${period} Tage`,
                                currentMonth: 'Dieser Monat',
                                pastMonth: 'Letzter Monat'
                           },
                           footer: {
                            apply: 'Auswählen',
                            cancel: 'Abbrechen'
                          }
                        }"
                    />
                </div >
            </div >

            <div >
                <DevelopmentChart :dataset="chartData" />
            </div >
        </div >
    </div >
</template >

<script >
import {
    ArrowPathRoundedSquareIcon,
    ArrowRightIcon,
    ChartPieIcon,
    GlobeAltIcon,
    MapIcon,
    PresentationChartLineIcon,
    ShieldCheckIcon,
    ShoppingBagIcon,
    ShoppingCartIcon
} from "@heroicons/vue/24/outline";
import DevelopmentChart from "../components/DevelopmentChart.vue";
import VueTailwindDatepicker from 'vue-tailwind-datepicker'

export default {
    components: {
        ShoppingCartIcon,
        ShoppingBagIcon,
        ShieldCheckIcon,
        ArrowRightIcon,
        MapIcon,
        PresentationChartLineIcon,
        ArrowPathRoundedSquareIcon,
        DevelopmentChart,
        VueTailwindDatepicker,
        ChartPieIcon,
        GlobeAltIcon,
    },

    data() {
        return {
            loadingSpinner: false,
            metrics: {
                importedOrders: '-',
                articlesInOrders: '-',
                processedRmas: '-',
                articlesPerOrder: '-',
                rmaRate: '-',
                topCountry: '-',
            },
            chartData: {},
            dateRangeMetrics: {
                startDate: this.getLastWeek(),
                endDate: this.getToday(),
            },
            dateRangeDevelopment: {
                startDate: this.getLastWeek(),
                endDate: this.getToday(),
            },
        };
    },

    watch: {
        dateRangeMetrics(newValue, oldValue) {
            this.fetchData();
        },
        dateRangeDevelopment(newValue, oldValue) {
            this.fetchData();
        },
    },

    computed: {
        fetchUrl() {
            return `/api/analytics?start_date_metric=${this.dateRangeMetrics.startDate}&end_date_metric=${this.dateRangeMetrics.endDate}&start_date_development=${this.dateRangeDevelopment.startDate}&end_date_development=${this.dateRangeDevelopment.endDate}`;
        },
    },

    methods: {
        getLastWeek() {
            const lastWeek = new Date();
            lastWeek.setDate(lastWeek.getDate() - 6);
            return lastWeek.toLocaleDateString('de-DE',
                {
                    year: '2-digit',
                    month: '2-digit',
                    day: '2-digit'
                }
            );
        },

        getToday() {
            return new Date().toLocaleDateString('de-DE',
                {
                    year: '2-digit',
                    month: '2-digit',
                    day: '2-digit'
                }
            );
        },

        fetchData() {
            this.loadingSpinner = true;
            axios.get(this.fetchUrl)
                .then(response => {
                    this.metrics = response.data.data.metrics;
                    this.chartData.orders = response.data.data.chart.orders;
                    this.chartData.articles = response.data.data.chart.articles;

                    this.submitSuccess = true;
                    this.$emit('close-me');
                })
                .catch(error => {
                    this.$swal({
                        title: 'Fehler beim Laden der Daten',
                        text: error.message,
                        icon: 'error',
                        showConfirmButton: true,
                        confirmButtonText: 'OK',
                    })
                })
                .finally(() => {
                    this.loadingSpinner = false;
                });
        },
    },

    mounted() {
        this.fetchData();
    }
}
</script >
