<template>
    <div v-if="loadingSpinner" class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-500 bg-opacity-75 flex flex-col items-center justify-center">
        <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
        <h2 class="text-center text-white text-xl font-semibold">Wird geladen...</h2>
        <p class="w-1/3 text-center text-white">Dies kann wenige Sekunden dauern. Bitte die Seite nicht schließen.</p>
    </div>

    <div class="flex flex-col">
        <div class="flex justify-end mb-7">
            <div class="mr-3">
                <button type="button"
                        @click="this.showCreateUser = true"
                        class="flex justify-center items-center bg-primary rounded-3xl px-3 py-2 text-white font-light h-10">
                    <PlusIcon class="h-5 inline-block mr-1"/>
                    Nutzer anlegen
                </button>
            </div>
        </div>

        <div class="mb-5 flex justify-between flex-wrap space-y-4 space-x-4">
            <div class="flex md:space-x-5 space-y-5 md:space-y-0 flex-wrap">
                <div class="w-full md:w-auto">
                    <input type="text" class="block w-full rounded-md border-gray-300 px-4 shadow-sm focus:border-primary focus:ring-primary sm:text-sm" placeholder="Suchen..." v-model="query" />
                </div>

                <div class="w-full md:w-auto">
                    <Menu as="div" class="relative inline-block text-left">
                        <div>
                            <MenuButton class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-gray-100">
                                {{ options.perPage }} Einträge
                                <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                            </MenuButton>
                        </div>

                        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                            <MenuItems v-model="options.perPage" class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div class="py-1">
                                    <MenuItem v-slot="{ active }">
                                        <div @click="options.perPage = 15" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">15 Einträge</div>
                                    </MenuItem>
                                    <MenuItem v-slot="{ active }">
                                        <div @click="options.perPage = 50" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">50 Einträge</div>
                                    </MenuItem>
                                    <MenuItem v-slot="{ active }">
                                        <div @click="options.perPage = 100" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">100 Einträge</div>
                                    </MenuItem>
                                    <MenuItem v-slot="{ active }">
                                        <div @click="options.perPage = 200" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">200 Einträge</div>
                                    </MenuItem>
                                </div>
                            </MenuItems>
                        </transition>
                    </Menu>
                </div>
            </div>
        </div>

        <div class="flex md:justify-end md:space-x-3 space-y-3 md:space-y-0 flex-wrap mb-6">
            <button v-for="filter in filterGroup" :key="filter.value"  type="button" :class="['ml-2 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-base leading-4 text-gray-700 hover:bg-slate-100 border border-slate-200', {'!bg-slate-200 !border-slate-300': filterGroupValue === filter.value}]" @click="filterGroupValue === filter.value ? filterGroupValue = null : filterGroupValue = filter.value">
                {{ filter.label }}
            </button>
        </div>

        <div>
            <v-server-table
                    url="/api/users"
                    :columns="columns"
                    :options="options"
                    ref="table"
                    @rowClick=openDetailsModal
            >
                <template v-slot:level="props">
                    {{roles[props.row.level]}}
                </template>
                <template v-slot:dhl_invoices="props">
                    <template v-if="props.row.dhl_invoices">Ja</template>
                </template>
                <template v-slot:status="props">
                    <div class="flex items-center">
                        <span class="inline-flex items-center rounded-full bg-error-over-ordering px-3 py-0.5 text-sm text-black ml-2"
                                :class="[props.row.status == 'active' ? 'bg-green-200' : 'bg-sky-200']">
                        {{states[props.row.status]}}
                    </span>
                    </div>
                </template>
                <template v-slot:customers="props">
                    <div class="flex items-center">
                        {{ props.row.customers.join(', ') }}
                    </div>
                </template>
            </v-server-table>
        </div>
    </div>
    <Modal :show="this.showDetails" @modalClose="this.showDetails = false">
        <template v-slot:body>
            <UserDetails :userId="this.userId" @closeMe="this.showDetails = false"></UserDetails>
        </template>
    </Modal>
    <Modal :show="this.showCreateUser" @modalClose="this.showCreateUser = false">
        <template v-slot:body>
            <UserCreate @closeMe="this.showCreateUser = false"/>
        </template>
    </Modal>
</template>
<script>
import {PlusIcon, ArrowDownTrayIcon} from '@heroicons/vue/24/outline';
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue'
import {ChevronDownIcon} from '@heroicons/vue/20/solid'
import Modal from '../components/Modal.vue';
import ContactButtons from '../components/ContactButtons.vue';
import debounce from 'debounce';
import VueTailwindDatepicker from 'vue-tailwind-datepicker'
import FormatDate from '../components/FormatDate.vue';
import UserCreate from "../components/UserCreate.vue";
import UserDetails from "../components/UserDetails.vue";

export default {
    components: {
        FormatDate,
        ContactButtons,
        PlusIcon,
        ArrowDownTrayIcon,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        ChevronDownIcon,
        Modal,
        VueTailwindDatepicker,
        UserCreate,
        UserDetails,
    },

    data() {
        return {
            query: '',
            dateRange: {
                startDate: '',
                endDate: ''
            },
            filterGroupColumn: 'status',
            filterGroupValue: '',
            loadingSpinner: false,
            filterGroup: [
                {
                    label: 'Alle',
                    value: '',
                },
                {
                    label: 'Aktiv',
                    value: 'active',
                },
                {
                    label: 'Inaktiv',
                    value: 'inactive',
                },
            ],
            columns: [
                'customers',
                'username',
                'email',
                'level',
                'status',
            ],
            options: {
                perPage: 15,
                headings: {
                    'customers': 'Kunden-Nr.',
                    'username': 'Benutzer',
                    'email': 'E-Mail',
                    'level': 'Nutzerrolle',
                    'dhl_invoices': 'DHL Rechnungen',
                    'status': 'Status',
                },
                texts: {
                    count: '{from} bis {to} von {count} Einträgen|{count} Einträge| Ein Eintrag',
                    filter: 'Filterergebnis:',
                    noResults: 'Keine Einträge vorhanden',
                    loading: 'Wird geladen',
                    filterPlaceholder: 'Suchen',
                },
                requestFunction(data) {
                    this.$parent.$parent.loadingSpinner = true;
                    data.query = this.$parent.$parent.query;
                    data.limit = this.$parent.$parent.options.perPage;

                    if (this.$parent.$parent.filterGroupValue && this.$parent.$parent.filterGroupColumn) {
                        data[this.$parent.$parent.filterGroupColumn] = this.$parent.$parent.filterGroupValue;
                    }

                    return axios.get(this.url, {
                        params: data
                    }).finally(() => {
                        this.$parent.$parent.loadingSpinner = false;
                    }).catch((e) => {
                        this.$parent.$parent.loadingSpinner = false;
                        this.dispatch('error', e);
                    });
                },
            },
            debouncedFilter: null,
            userId: null,
            showDetails: false,
            showCreateUser: false,
            roles: {
                admin: "Administrator",
                customer: "Kunde",
                manager: "Manager",
            },
            states: {
                active: "Aktiv",
                inactive: "Inaktiv"
            },
        };
    },

    watch: {
        query(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.filter();
            }
        },
        dateRange(newValue, oldValue) {
            this.filter();
        },
        'options.perPage': {
            handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.$refs.table?.setLimit(this.options.perPage);
                }
            }
        },
        filterGroupValue(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.filter();
            }
        },
    },

    methods: {
        filter() {
            this.debouncedFilter ? this.debouncedFilter() : this.filterTable();
        },

        filterTable() {
            this.$refs.table?.setFilter(this.query);
        },

        openDetailsModal(event) {
            this.userId = event.row.id;
            this.showDetails = true;
        },

        closeDetailsModal() {
            this.showDetails = false;
        },

    },

    mounted() {
        this.debouncedFilter = debounce(() => {
            this.filterTable();
        }, 200)
    },
    beforeMount() {
        this.filterGroupValue = this.filterValue;
    },
    props: ['filterValue']
}
</script>
