import {createRouter, createWebHistory} from "vue-router";
import Login from '../pages/Login.vue';
import OrdersIndex from '../pages/OrdersIndex.vue';
import RmaIndex from "../pages/RmaIndex.vue";
import GoodsIndex from "../pages/GoodsIndex.vue";
import ProductsIndex from "../pages/ProductsIndex.vue";
import UsersIndex from "../pages/UsersIndex.vue";
import ForgotPassword from "../pages/ForgotPassword.vue";
import Cookies from 'js-cookie';
import Analytics from "../pages/Analytics.vue";
import TeamDashboard from "../pages/TeamDashboard.vue";
import Dashboard from "../pages/Dashboard.vue";

const routes = [
    {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
        beforeEnter(to, from, next) {
            if (isTeam()) {
                router.push('/team');
            }

            if (isAdmin() || isManager() || isCustomer() || isTeam()) {
                next();
                return;
            }

            router.push('/login');
        }
    },
    {
        path: "/team",
        name: "Team",
        component: TeamDashboard,
        beforeEnter(to, from, next) {
            if (isTeam() || isAdmin()) {
                next();
                return;
            }

            router.push('/login');
        }
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        beforeEnter(to, from, next) {
            if (isAdmin() || isManager() || isCustomer()) {
                router.push('/');
                return;
            }

            next();
        },
    },
    {
        path: "/forgot-password",
        name: "Password vergessen",
        component: ForgotPassword,
    },
    {
        path: "/orders/:filterValue?",
        name: "Aufträge",
        component: OrdersIndex,
        props: true,
        beforeEnter(to, from, next) {
            if (isAdmin() || isManager() || isCustomer()) {
                next();
                return;
            }

            router.push('/');
        },
    },
    {
        path: "/rma/:filterValue?",
        name: "Retouren",
        component: RmaIndex,
        props: true,
        beforeEnter(to, from, next) {
            if (isAdmin() || isManager() || isCustomer()) {
                next();
                return;
            }

            router.push('/');
        },
    },
    {
        path: "/goods/:filterValue?",
        name: "Lieferungen",
        component: GoodsIndex,
        props: true,
        beforeEnter(to, from, next) {
            if (isAdmin() || isManager() || isCustomer()) {
                next();
                return;
            }

            router.push('/');
        },
    },
    {
        path: "/products/:filterValue?",
        name: "Bestände",
        component: ProductsIndex,
        props: true,
        beforeEnter(to, from, next) {
            if (isAdmin() || isManager() || isCustomer()) {
                next();
                return;
            }

            router.push('/');
        },
    },
    {
        path: "/analytics",
        name: "Kennzahlen",
        component: Analytics,
        beforeEnter(to, from, next) {
            if (isAdmin() || isManager() || isCustomer()) {
                next();
                return;
            }

            router.push('/login');
        }
    },
    {
        path: "/users/:filterValue?",
        name: "Benutzer",
        component: UsersIndex,
        props: true,
        beforeEnter(to, from, next) {
            if (isAdmin() || isManager()) {
                next();
                return;
            }

            router.push('/');
        },
    },
    {
        path: "/logout",
        name: "Logout",
        redirect: to => {
            axios.post('/api/logout', {'token': Cookies.get('token')})
                .then(response => {
                    Cookies.remove('level', {path: ''})
                    Cookies.remove('token', {path: ''})
                    Cookies.remove('tags', {path: ''})
                    location.href = '/';
                });
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from) => {
    let token = Cookies.get('token');
    let level = Cookies.get('level');

    if ((!token || !level) && to.path !== '/login' && to.path !== '/forgot-password') {
        router.push('/login');
        return;
    }

    return true;
})


export default router;
